import "./Footer.css"

const Footer = props => {
  return (
    <div className='mint-footer'>
      <a href='https://supperclub.xyz' target='_blank'>
        <img className='footer-logo' src='https://cdn.hngr.co/supperclub-footer.png'/>
      </a>

      <a 
        href='https://twitter.com/SupperclubXyz' 
        target='_blank'
      >
        Twitter
      </a>

      <a
        href={window.DISCORD_LINK}
        target='_blank'
      >
        Discord
      </a>

      <a 
        href='https://fatburger.com/nft-nationalhamburgerday' 
        target='_blank'
      >
        Terms &amp; Conditions
      </a>

 



    </div>

  )
}

export default Footer

