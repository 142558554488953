import React, { Component } from "react"
import TutorialContextHOC from "./contexts/TutorialContextHOC"
import Tutorial from "./Tutorial"

const Api = require ("./api")

class Blockchain extends Component {


  airDrop(){
    Api.callApi({
      endpoint:'/fcsc/airdrop-solana',
      method:'post',
      data:{
        address:window.solana.publicKey.toString()
      },
      success:(resp)=>{
        alert('success')
      },
      failure:(err)=>{
        alert('error')
      }
    })
  }

  airDropCshells(){
    Api.callApi({
      endpoint:'/fcsc/airdrop-cshells',
      method:'post',
      data:{
        address:window.solana.publicKey.toString()
      },
      success:(resp)=>{
        alert('success')
      },
      failure:(err)=>{
        console.log("taj", err)
        alert('error')
      }
    })
  }


  render(){
    return (
      <Tutorial
        tip='FCSC is an acronym'
        page='FCSC'
        title='Friends of Captain Socail Club'
        questions={{
          'How much wood can a wood chuck chuck?':'No one knows',
          'Are squirrels rodents?': 'Yes, squirrels are rodents. Some call them rats with bushy tails.'
        }}
        right={
          <>
            <div className='tutorial-right-title'>FCSC</div>
            <img 
              className='tutorial-right-img'
              alt='FCSC'
              src='https://sugargeekshow.com/wp-content/uploads/2019/10/chocolate-chip-muffins-featured.jpg'
            />
            <div className='tutorial-right-text'>The FCSC is the place to be.</div>

            <button
              onClick={()=>this.airDrop()}
              style={{marginTop:'30px'}}
              className='button button-text-only'
            >
              air drop me some SOL
            </button>

            <button
              onClick={()=>this.airDropCshells()}
              style={{marginTop:'30px'}}
              className='button button-text-only'
            >
              air drop me some CSHELLS 
            </button>

            <div 
              style={{marginTop:'30px'}} 
              className='button button-text-only'
              onClick={()=>this.props.tutorial.next('fcsc')}
            >
              Continue
            </div>

          </>
        }
      />
    )
  }
}
      
export default TutorialContextHOC(Blockchain) 
