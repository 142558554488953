import React, { Component } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Countdown from 'react-countdown';
import WalletButton from "./WalletButton"
import withWallet from "../withWallet"
import classnames from "classnames"
import withRouterAndRef from "../withRouterAndRef"
import DiscordButtonBlack from "./DiscordButtonBlack"
import "./LandingPage.css"
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { Connection as MPConnection, programs } from '@metaplex/js'
import { isMobile, isChrome } from 'react-device-detect';
import { Link } from "react-router-dom"
const { metadata: { Metadata } } = programs;

const Api = require ("../api")
const METADATA_PROGRAM_ID = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
const METADATA_PREFIX = "metadata";



// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if(days < 10) days = '0' + days
  if(hours < 10) hours = '0' +  hours
  if(minutes < 10) minutes = '0' + minutes
  if(seconds < 10) seconds = '0' + seconds
  if (completed) {
    // Render a completed state
    return (<div> COMPLETE </div>) 
  } else {
    return(
      <div className='countdown-clock'>
        {days}:{hours}:{minutes}:{seconds}
      </div>
    )
  }
};

class MintPage extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      page:'home',
      publicKey:null,
      isMobile:isMobile,
      FAT:window.subdomain.indexOf('fat') > -1
    }
    window.mint = this
    this.aboutRef = React.createRef()
    this.howToRef = React.createRef()
  }
  
  componentDidMount(){
    this.loadMintInfo()
  }

  componentDidUpdate(prevProps, prevState){

    if(
      this.state.publicKey && 
      prevState.publicKey &&
      !this.state.mint_full
    ){
      this.props.history.push("/minting")
    }

    if(
      this.props.publicKey  &&
      this.state.publicKey && 
      this.props.publicKey.toString() !== this.state.publicKey.toString()
    ){
      this.setState({publicKey:this.props.publicKey}, ()=>{
          this.checkForOwner()
          this.checkForWhitelist()
        })
    }

    if(
      this.props.publicKey &&
      !this.state.publicKey 
    ){
      this.setState({publicKey:this.props.publicKey},  ()=>{
          this.checkForOwner()
          this.checkForWhitelist()
        })
    }

    if(this.state.publicKey && !this.props.publicKey){
      this.setState({publicKey:null, onAllowList:null})
    }
  }

  async getOwnedNFtDetails(){
    let nft = this.state.nft
    let c2 = new MPConnection('mainnet-beta');

    let metadataPK = await programs.metadata.MetadataProgram.findProgramAddress(
      [
        Buffer.from(METADATA_PREFIX),
        new PublicKey(METADATA_PROGRAM_ID).toBuffer(),
        new PublicKey(nft).toBuffer(),
      ],
      new PublicKey(METADATA_PROGRAM_ID)
    )

    try{
      let val  = await Metadata.load(c2, metadataPK.toString());
      let metadata = val['data']
      let uri = metadata['data']['uri']
      let resp = await fetch(uri)
      let url_data = await resp.json()
      this.setState({metadata:url_data})
 
    } catch(err){
      console.log(err)
    }

  }


 async checkForOwner(){
    console.log("check for owner....")
    let connection
    let accounts
    let success = false
    try {
      connection = new solanaWeb3.Connection(
      'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/', 'confirmed')
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    }
    catch (err){
      console.log("EXCEPTIOPN ERROR", err)
      connection = new solanaWeb3.Connection(
      'https://nd-135-906-691.p2pify.com/58f40b25ff76a90476de9023bd163e25', 'confirmed')
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    } finally {
      if(!success){
        setTimeout(()=>this.checkForOwner, 3000)
        return
      }
    }

    let mints = []
    for(let i in accounts){
      let amt = accounts[i].account.data['parsed']['info']['tokenAmount']['uiAmount']
      if(amt === 1){
        mints.push(accounts[i].account.data['parsed']['info']['mint'])
      }
    }

    Api.callApi({
      endpoint:"/fcsc/check-if-own-nft",
      method:'post',
      data:{
        mints:mints,
        mint_url:window.subdomain
      },
      success:(resp)=>{
        if(resp.owner){
          this.props.history.push('/owner')
        }
      },
      failure:(err)=>{
        alert('Error verifying NFT ownership')
      }
    })
  }


  async checkForWhitelist(){
    Api.callApi({
      endpoint:"/fcsc/check-if-on-whitelist",
      method:'post',
      data:{
        wallet_address:this.state.publicKey.toString(),
        mint_url:window.subdomain
      },
      success:(resp)=>{
        if(resp.allowlist){
          this.setState({onAllowList:true})
        }
      },
      failure:(err)=>{
        alert('Error verifying NFT ownership')
      }
    })



    return
    /*
    var connection = new solanaWeb3.Connection(
    'https://api.mainnet-beta.solana.com', 'confirmed')
    // see if they have any matching NFTs
    let accounts = await connection.getParsedProgramAccounts(
      new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
      {
        filters: [
          {
            dataSize: 165, // number of bytes
          },
          {
            memcmp: {
              offset: 32, // number of bytes
              bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
            },
          },
        ],
      }
    );

    let mints = []
    for(let i in accounts){
      let amt = accounts[i].account.data['parsed']['info']['tokenAmount']['uiAmount']
      if(amt === 1){
        mints.push(accounts[i].account.data['parsed']['info']['mint'])
      }
    }

    Api.callApi({
      endpoint:"/fcsc/check-if-own-nft",
      method:'post',
      data:{
        mints:mints,
        mint_url:window.subdomain
      },
      success:(resp)=>{
        if(resp.owner){
          this.setState({owner:true, nft:resp.nft, page:'owner'},()=>this.getOwnedNFtDetails())
        }
      },
      failure:(err)=>{
        alert('Error verifying NFT ownership')
      }
    })
    */
  }

  navigateToAnchor(counter){
    if(counter >= 20) return
    
    let params = new URLSearchParams(window.location.search)
    if(params && params.get('anchor')){
      if(params.get('anchor') === 'howto'){
         if(this.howToRef && this.howToRef.current){
            let y = window.mint.howToRef.current.getBoundingClientRect().top + window.pageYOffset - 130
            window.scrollTo(0, y)
          } else {
            setTimeout(()=>this.navigateToAnchor(counter+1), 100)
          }
      }
      
      if(params.get('anchor') === 'about'){
         if(this.aboutRef && this.aboutRef.current){
            let y = window.mint.aboutRef.current.getBoundingClientRect().top + window.pageYOffset - 130
            window.scrollTo(0, y)
          } else {
            setTimeout(()=>this.navigateToAnchor(counter+1), 100)
          }
      }

    }
  }


  loadMintInfo(){
    Api.callApi({
      endpoint:'/api/mint-info/'+window.subdomain,
      method:'get',
      success:(resp)=>{
        if(resp.error){
          this.setState({error:resp.error})
        } else {
          this.setState({
            name:resp.name,
            mint_date_str:resp.date_str,
            premint_date_str:resp.premint_date_str,
            ms:resp.ms,
            ms_premint:resp.ms_premint,
            mint_open:resp.mint_open,
            premint_open:resp.premint_open,
            minted:resp.minted,
            limit:resp.limit,
            loading:false,
            mint_full:resp.mint_full
          }, ()=>this.navigateToAnchor(1))
        }
      },
      failure:(err)=>{
        alert('Error loading.... Please refresh')
      }
    })
  }


  checkForMinting(){
    Api.callApi({
      endpoint:"/fcsc/check-if-nft-transferred",
      method:'post',
      data:{
        address:this.props.publicKey.toString(),
        nft_id:this.state.nft_id
      },
      success:(resp)=>{
        if(resp.ready){
          alert('got NFT!')
          this.checkForOwner()
        } else{
          setTimeout(()=>this.checkForMinting(), 3000)
        }
      },
      failure:(err)=>{
        setTimeout(()=>this.checkForMinting(), 3000)
      }
    })
 
  }

  premintDate(){
    let idx = this.state.premint_date_str.indexOf('|')
    let str = this.state.premint_date_str
    return str.substr(0, idx)
  }

  renderMobile(){
    return (
      <div className='mobile-container'>
        <div className='mobile-header'>
          {this.state.FAT ? (
            <img 
              style={{height:'30px', cursor:'pointer'}} 
              src='https://cdn.hngr.co/supperclub-fat-logo.gif' 
            />
          ) : (
            <img 
              style={{height:'30px', cursor:'pointer'}} 
              src='https://cdn.hngr.co/Irazu-Supper-Club-Logo.gif' 
            />
          )}
        </div>
        <div className='mobile-banner-warning'>
          <Warning />
          <div>
            Solana compatible browser required to mint. 
            <Link to='/unsupported' style={{textDecoration:'underline'}}>Learn more</Link>
          </div>
        </div>
        
        <div className='mobile-page-content-container'>
          <div className='mobile-landing-page-content'>
            {this.state.FAT ? (
              <>
                <div className='top-subtitle-mobile'>
                  LIMITED EDITION
                </div>
                <div className='title-mobile'>
                  NATIONAL HAMBURGER DAY NFT ‘22
                </div>

                <div className='mobile-img-container'>
                  <img src='https://cdn.hngr.co/fat-cropped.gif' />
                </div>

                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <div className='mobile-landing-blurb'>
                    Supper Club and Fatburger team up to celebrate the tastiest holiday in Web3.
                  </div>
                </div>
              </>


            ) : (
            <>
              <div className='top-subtitle-mobile'>
                100 SERIES ZERO NFTS
              </div>

              <div className='title-mobile'>PROOF OF SHAKE</div>

              <div className='mobile-img-container'>
                <img src='https://cdn.hngr.co/irazu_cropped_mint.gif' />
              </div>

              <div className='mobile-landing-blurb'>
                Irazu - Bucktown's famous Costa Rican restaurant teams up with Supper Club to drop 100 limited edition NFTs with perks to feed Web3. Grab one before they’re all gone!
              </div>



              <div
                className='generic-green-button'
                onClick={()=>this.props.history.push(`/mint/premint-eligibility-ordering`)}
                style={{width:'100%', boxSizing:'border-box', marginBottom:'16px'}}
              >
                <div>Can I Mint Early?</div>
              </div>
            </>
            )}

            <DiscordButtonBlack />

            <div className='mobile-sections-container'>
              {this.state.FAT && (
                <div className='mobile-landing-section' >
                  <div className='mobile-section-title'>MINT DETAILS</div>
                  <div className='mobile-mint-details-container'>
                    
                    <div className='mobile-mint-section-detail'>
                      <div className='mobile-mint-detail-top'>Network</div>
                      <div className='mobile-mint-detail-bottom'>Solana</div>
                    </div>

                    <div className='mobile-mint-section-detail'>
                      <div className='mobile-mint-detail-top'>Mint Price</div>
                      <div className='mobile-mint-detail-bottom'>FREE</div>
                    </div>
                    
                    <div className='mobile-mint-section-detail'>
                      <div className='mobile-mint-detail-top'>Quantity</div>
                      <div className='mobile-mint-detail-bottom'>500</div>
                    </div>

                    <div className='mobile-mint-section-detail' style={{marginBottom:'0px'}}>
                      <div className='mobile-mint-detail-top'>Drop Date</div>
                      <div className='mobile-mint-detail-bottom'>5/28/22</div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.FAT && (
                <div className='mobile-landing-section' >
                  <div className='mobile-section-title'>A NFT WITH UTILITY YOU CAN EAT</div>

                  <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <img className='mobile-fat-image' src='https://cdn.hngr.co/fatburger-square.png'/>
                  </div>
                  
                  <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <div className='fatburger-mobile-blurb'>
                      Fatburger has partnered with the Supper Club to launch a brand new NFT on the Solana blockchain. These NFTs are completely FREE and will entitle holders to real-world rewards.<br/><br/>

                      Holders will be able to claim a free Original Fatburger from Fatburger.com.
                    </div>
                  </div>

                  <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <a
                      className='mobile-green-button'
                      href='https://www.supperclub.xyz/fatburger'
                    >
                      <div>Learn More</div>
                    </a>
                  </div>

                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
                    <a
                      className='mobile-black-button'
                      href='https://fatburger.com/menu-index'
                    >
                      <div>Check Out Our Menu</div>
                    </a>
                  </div>



                </div>
              )}


              <div className='mobile-landing-section' >
                <div className='mobile-section-title' style={{fontSize:'44px'}}>
                  THE <span style={{whiteSpace:'nowrap'}}>SUPPER CLUB</span>
                </div>
                
                <div style={{display:'flex', justifyContent:'center'}}>
                  <img src='https://cdn.hngr.co/foodmojis.gif' className='mobile-landing-img' />
                </div>



                  <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <div className='mobile-landing-blurb' style={{marginTop:'16px', marginBottom:'24px'}}>
                      Supper Club is a Web3 eating community where you get rewarded for supporting your favorite restaurants.
                      <br/><br/>

                      We curate custom Web3 experiences by partnering with the best restaurants and offering our hungry community access to exclusive deals & rewards.
                    </div>
                  </div>


                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'16px'}}>
                    <a
                      className='mobile-green-button'
                      href='https://supperclub.xyz'
                    >
                      <div>Check Out Supper Club</div>
                    </a>
                  </div>

                <DiscordButtonBlack />

              </div>


              <div className='mobile-footer'>
                <a href='https://supperclub.xyz'>
                  <img src='https://cdn.hngr.co/supperclub-footer.png' style={{width:'185px'}}/>
                </a>

                <a className='mobile-footer-link' href='https://twitter.com/SupperclubXyz' style={{margin:'32px 0px'}}>
                  Twitter
                </a>

                <a className='mobile-footer-link' href={window.DISCORD_LINK}>
                  Discord 
                </a>


              </div>

            </div>

          </div>


        </div>
      </div>
    )
  }

  render(){
     if(this.state.loading){
      return (
        <div className='loading-page'>
          <img src='https://cdn.hngr.co/burger-loader.gif' />
        </div>
      )
    } else if (this.state.isMobile) {
      return this.renderMobile()
    } else{
      return (
        <div className='mint-page grid-background'> 
          {!isChrome && (
            <div className='unsupported-banner-desktop'>
              <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Warning style={{marginRight:'10px'}}/>
                <div>
                  Solana compatible browser required to mint. 
                  <Link to='/unsupported' style={{textDecoration:'underline'}}>Learn more</Link>
                </div>
              </div>
            </div>
          )}

          {isChrome && false && (
            <div className='unsupported-banner-desktop'   style={{backgroundColor:'#EBC35C', fontFamily:'Space Mono'}}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'950px'}}>
                <Warning style={{marginRight:'10px', height:'50px', width:'60px'}}/>
                <div>
                  Attention: The Solana blockchain is currently experiencing network issues. If your mint does not go through, please try again later. Minting times are expected to take longer than usual.
                </div>
              </div>
            </div>
          )}
          <Header 
            about={()=>{
              if(this.aboutRef && this.aboutRef.current){
                  let y = window.mint.aboutRef.current.getBoundingClientRect().top + window.pageYOffset - 130
                  window.scrollTo(0, y)
              }
            }}

            howTo={()=>{
              if(this.howToRef && this.howToRef.current){
                  let y = window.mint.howToRef.current.getBoundingClientRect().top + window.pageYOffset - 130
                  window.scrollTo(0, y)
              }
            }}
 
          
          
          />
          <div className='mint-page-above-the-fold'>
            <div className='mint-page-content mint-page-top-content-outer'>
              <div className='mint-page-top-content'>
                <div className='mint-page-top-content-left'>

                  <div className='mint-page-top-tag-row'>
                    <div className='top-tag-row-title'>
                      {this.state.FAT ? (
                        <>LIMITED EDITION</>
                      ) : (
                        <>LIMITED EDITION</>
                      )}
                    </div>
                    {this.state.mint_full ? ( 
                      <div className='top-tag-grey'>
                        <div>ALL CLAIMED</div> 
                      </div>
                    ) : (
                      <div className='top-tag'>
                        <div>{this.state.minted}/{this.state.limit} MINTED</div> 
                      </div>
                    )}
                  </div>

                  <div className='mint-page-above-fold-title-huge'>
                    {this.state.FAT ? (
                      <>NATIONAL HAMBURGER DAY NFT ‘22</>
                    ) : (
                      <>PROOF OF SHAKE</>
                    )}
                  </div>

                  <div className='mint-page-above-fold-blurb'>
                    {this.state.FAT ? (
                      <>
                        Supper Club and Fatburger team up to celebrate the tastiest holiday in Web3.
                      </>
                    ) : (
                      <>Irazu - Bucktown's famous Costa Rican restaurant teams up with Supper Club to drop 100 limited edition NFTs with perks to feed Web3. Grab one before they’re all gone!</>
                    )}
                  </div>

                  <div className='mint-page-buttons-container'>
                    {!this.state.mint_full &&  this.state.mint_open && (
                        <div 
                          onClick={()=>this.props.history.push(`/minting` + window.location.search)}
                          className='mint-page-green-button'
                        >
                          <div>Mint Now</div>
                        </div>
                     )}

                    <a
                      style={this.state.mint_open && !this.state.mint_full ? {marginLeft:'24px'} : {}}
                      className='mint-page-black-button'
                      target='_blank'
                      href={window.DISCORD_LINK}
                    >
                      <div>Join Our Discord</div>
                      <Discord style={{marginLeft:'8px'}}/>
                    </a>


                  </div> {/* mint-page-buttons-container */ }

                </div>
                {this.state.FAT ? ( 
                  <img 
                    className='mint-page-top-content-image'
                    src='https://cdn.hngr.co/fat-cropped.gif'
                  />
                ) : (
                  <img 
                    className='mint-page-top-content-image'
                    src='https://cdn.hngr.co/irazu_cropped_mint.gif'
                  />
                )}
              </div>

            </div> {/*mint-page-content*/}

            <div className='mint-green-bar' style={this.state.mint_full ? {borderTopColor:'#BBBBBB'} : {}}>
              <div className='mint-page-content' style={{paddingRight:'142px', paddingLeft:'142px', boxSizing:'border-box'}} >
                <div className='mint-green-bar-content'  style={ (this.state.mint_open || this.state.FAT) ? {justifyContent:'center'} : {}}>


                  {!this.state.mint_open && this.state.premint_date_str && (
                    <div className='green-bar-countdown-section'>
                      <div className='green-bar-countdown-section-left'>
                        <div className='green-bar-countdown-section-left-top'>
                          Pre-Access Mint
                        </div>
                        <div className='green-bar-countdown-section-left-bottom'>
                          {this.state.premint_date_str}
                        </div>
                      </div>

                      {!this.state.premint_open ? (
                        <Countdown date={Date.now() + this.state.ms_premint} renderer={renderer}/>
                      ) : (
                        <div className='green-bar-open-tag'>
                          <div>MINT OPEN</div>
                        </div>
                      )}

                    </div>
                  )}

                  <div className='green-bar-countdown-section'>
                    {!this.state.mint_full && (
                      <div className='green-bar-countdown-section-left'>
                        <div className='green-bar-countdown-section-left-top'>
                          Public Mint
                        </div>
                        <div className='green-bar-countdown-section-left-bottom'>
                          {this.state.mint_date_str}
                        </div>
                      </div>
                    )}
                    {this.state.mint_open ? (
                      <>
                        {this.state.mint_full ? ( 
                          <div className='green-bar-sold-out'>
                            <div>NFT IS SOLD OUT</div>
                          </div>

                        ) : (
                          <div className='green-bar-open-tag'>
                            <div>MINT OPEN</div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Countdown 
                        onComplete={()=>this.setState({mint_open:true})}
                        date={Date.now() + this.state.ms} 
                        renderer={renderer}
                      />

                    )}
                  </div>
                </div> {/* mint-green-bar-content */}
              </div> {/* mint-page-content inside green bar */}



            </div> {/* mint-green-bar */}
          </div> {/* mint-page-above-the-fold*/}

          {!this.state.FAT && (
            <div className='grey-banner-thing'>
              <div className='grey-banner-inner'>
                <div className='grey-banner-text'>100 LIMITED EDITION NFTS FOR <span className='green-font'>IRAZU’S</span> BIGGEST FANS</div>
              </div>
            </div>
          )}

         <span ref={this.aboutRef}></span> 


         {this.state.FAT && (
           <MintPageSection>
             <div style={{width:'100%'}}>
                <div className='mint-section-header mint-section-header-centered'>
                  MINT DETAILS
                </div>
                <div className='mint-four-boxes'>
                  <div className='mint-four-boxes-box'>
                    <div className='mint-four-boxes-box-title'>Network</div>
                    <div className='mint-four-boxes-box-subtitle'>Solana</div>
                  </div>

                  <div className='mint-four-boxes-box'>
                    <div className='mint-four-boxes-box-title'>Mint Price</div>
                    <div className='mint-four-boxes-box-subtitle'>FREE</div>
                  </div>

                  <div className='mint-four-boxes-box'>
                    <div className='mint-four-boxes-box-title'>Quantity</div>
                    <div className='mint-four-boxes-box-subtitle'>500</div>
                  </div>

                  <div className='mint-four-boxes-box'>
                    <div className='mint-four-boxes-box-title'>Drop Date</div>
                    <div className='mint-four-boxes-box-subtitle'>5/28/22</div>
                  </div>
                </div>
              </div>
           </MintPageSection>
          )}


         <MintPageSection>
            <div className='mint-section-flex'>
              <div className='emojisection-left' style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                <div>
                  <div className='mint-section-header'>
                    AN NFT WITH UTILITY YOU CAN EAT
                  </div>

                  <div className='mint-section-blurb'>
                    Fatburger has partnered with the Supper Club to launch a brand new NFT on the Solana blockchain. These NFTs are completely FREE and will entitle holders to real-world rewards.
                    <br/><br/>
                    Holders will be able to claim a free Original Fatburger from Fatburger.com.
                  </div>

                </div>
                
                <div className='mint-section-buttons-container'>
                  <a
                    className='mint-section-green-button' 
                    style={{marginRight:'16px'}}
                    href='https://www.supperclub.xyz/fatburger'
                    target='_blank'
                  >
                    <div>Learn More</div>
                  </a>

                  <a className='mint-section-black-button' target='_blank' href='https://fatburger.com/menu-index'>
                    <div>View Our Menu</div>
                  </a>
                </div>

              </div>
            
              <img
                src='https://cdn.hngr.co/Fatburger-Photo.png'
                className='fatburger-photo-vert'
              />
            </div>
          </MintPageSection>


          {this.state.FAT ? (
            <MintPageSection>
              <div style={{width:'100%'}}>
                <div className='mint-section-header mint-section-header-centered'>
                  BENEFITS
                </div>
                <div className='mint-section-three-pics'>
                  <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/ownapiece.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      Own A Piece
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      Own a piece of history by minting our first NFT with utility you can eat.
                    </div>
                  </div>
                  
                  <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/getrewards.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      Get Rewards
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      Free Original Fatburgers for all Fatburger National Hamburger Day ’22 NFT holders!
                    </div>
                  </div>
   
                    <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/wizardry.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      Web3 Wizardry
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      New in town? Check out our&nbsp;
                      <span 
                        style={{textDecoration:'underline', cursor:'pointer'}} 
                        onClick={()=>{
                          if(this.howToRef && this.howToRef.current){
                              let y = window.mint.howToRef.current.getBoundingClientRect().top + window.pageYOffset - 130
                              window.scrollTo(0, y)
                          }}
                        }
                      >
                        FAQs
                      </span>
                      , mint an NFT and learn Web3 along the way!
                    </div>
                  </div>
                </div>
              </div>
            </MintPageSection>

          ) : (
            <MintPageSection>
              <div style={{width:'100%'}}>
                <div className='mint-section-header mint-section-header-centered'>
                  BENEFITS
                </div>
                <div className='mint-section-three-pics'>
                  <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/solana-blob2.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      OFFERS &amp; REWARDS
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      As an NFT holder, shakes may or may not appear throughout ordering
                    </div>
                  </div>
                  
                  <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/solana-blob3.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      ALLOW LIST
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      Access to our exclusive NFT drop & bustling community of foodies
                    </div>
                  </div>
   
                    <div className='mint-section-three-pic-container'>
                    <img src='https://cdn.hngr.co/solana-blob4.png'/>
                    <div className='mint-section-three-pic-green-text'>
                      WEB3 EXPLORATION
                    </div>
                    <div className='mint-section-three-pic-grey-text'>
                      Join us on our latest programs and new experiments in Web3.
                    </div>
                  </div>
                </div>
              </div>
            </MintPageSection>
          )}

         <span ref={this.howToRef}></span> 
          {this.state.FAT && (
            <MintPageSection>
              <div className='mint-howto'>
                
                <div className='mint-howto-left'>
                  <div className='metaverse-complicated'>The Metaverse can be complicated...</div>
                  <div className='we-know'>WE KNOW YOU HAVE QUESTIONS</div>
                  <div className='dont-see'>
                    Don’t see your question in the FAQ? Join the Supper Club Discord and ask away!
                  </div>
                  <div style={{display:'flex'}}>
                    <a className='mint-section-green-button' target='_blank' href={window.DISCORD_LINK}>
                      <div>Join Our Discord</div>
                      <DiscordBlack style={{marginLeft:'8px', fill:'black'}}/>
                    </a>
                  </div>
                </div>
               
               <div className='howto-section-right'>
                  <div className='howto-green-subtitle' style={{marginTop:'0px'}}>
                    What does "minting" mean?
                  </div>

                  <div className='howto-section-blurb'>
                    NFTs come into existence on the blockchain by means of a process called minting. When minting opens, you can visit the mint page and connect your wallet to claim 1 of 500 Fatburger National Hamburger Day 2022 NFTs.
                  </div>

                  <div className='howto-green-subtitle'>
                    What's a wallet?
                  </div>

                  <div className='howto-section-blurb'>
                    Unlike a normal wallet, which can hold actual cash, crypto wallets technically don’t store your crypto. Your holdings live permanently on the blockchain, but can only be accessed using a private key - your crypto wallet. 

                    <br/><br/>
                    {this.state.FAT && ( 
                      <>
                        To mint a Fatburger National Hamburger Day 2022 NFT, you’ll need a wallet to access the Solana blockchain. Making a wallet is free. We recommend using a Phantom wallet, which is a Google Chrome extension that allows you to access the Solana blockchain.
                      </>
                    )}

                  </div>
      
                  <div className='howto-grey-instruction-box'>
                    <div className='howto-instruction-box-title'>
                      Phantom Wallet Set-Up
                    </div>

                    <div className='howto-instruction-hr'></div>

                    <div className='howto-instruction'>
                      <div className='howto-instruction-number'>1</div>
                      <div className='howto-instruction-right'>
                        <div className='howto-instruction-title'>
                          Download the plugin from  <a href='https://phantom.app/download' target='_blank'>
                            <span className='green-font'>phantom.app/download</span>
                          </a>
                        </div>
                        <div className='howto-instruction-text'>
                          Select your browser and follow instructions to install.
                        </div>
                      </div>
                    </div>

                    <div className='howto-instruction'>
                      <div className='howto-instruction-number'>2</div>
                      <div className='howto-instruction-right'>
                        <div className='howto-instruction-title'>
                          Select “Create New Wallet”
                        </div>
                        <div className='howto-instruction-text'>
                          Follow the instructions from Phantom and don’t forget to save your Recovery Phrase - you need it to access your account.
                        </div>
                      </div>
                    </div>

                    <div className='howto-instruction'>
                      <div className='howto-instruction-number'>3</div>
                      <div className='howto-instruction-right'>
                        <div className='howto-instruction-title'>
                          Pin the extension to your browser
                        </div>
                        <div className='howto-instruction-text'>
                          Follow the instructions from Phantom and don’t forget to save your Recovery Phrase - you need it to access your account.
                        </div>
                      </div>
                    </div>

                  </div> {/* instrution box */} 


                  <div className='howto-green-subtitle'>
                    How do I claim my free Original Fatburger?
                  </div>

                  <div className='howto-section-blurb'>
                    Visit <a style={{color:'white', textDecoration:'underline'}} href='https://fatburger.com' target='_blank'>Fatburger.com</a>, click "Order Online", and pick your store. Then, click "Connect Wallet" on our ordering page. If there's a Fatburger NFT in your wallet, you can claim your rewards directly on the ordering page!
                  </div>

                  <div className='howto-green-subtitle'>
                    What do I get for owning a Fatburger NFT?
                  </div>

                  <div className='howto-section-blurb'>
                    Make history – be one of the first Fatburger Fans to own an NFT with utility you can eat. We’re giving away one FREE Original Fatburger to holders in the month of June.
                  </div>

                </div>
   
              </div>
            </MintPageSection>
          )}
          
          <MintPageSection>
           <div className='mint-section-flex'>
              <div className='emojisection-left'>
                <div className='mint-section-header'>
                  THE SUPPER CLUB
                </div>

                <div className='mint-section-blurb'>
                  Supper Club empowers foodies to support their favorite local restaurants through crypto.
                  <br/><br/>

                  Supper Club teaches people about Web3 and its real world applications. We curate custom experiences by partnering with the best local restaurants to start your journey.
                </div>
                
                <div className='mint-section-buttons-container'>
                  <a
                    className='mint-section-green-button' 
                    style={{marginRight:'16px'}}
                    href='https://www.supperclub.xyz/'
                    target='_blank'
                  >
                    <div>Check Out Supper Club</div>
                  </a>

                  <a className='mint-section-black-button' target='_blank' href={window.DISCORD_LINK}>
                    <div>Join Our Discord</div>
                    <Discord style={{marginLeft:'8px'}}/>
                  </a>
                </div>

              </div>
            
              <img
                src='https://cdn.hngr.co/foodmojis.gif'
                className='blueburger'
              />
            </div>
          </MintPageSection>

          {!this.state.FAT && (
            <MintPageSection>
              <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <div style={{width:'536px'}}>
                  <div className='mint-section-header'>
                    THE <span className='green-font'>IRAZU</span> STORY 
                  </div>
                  <div className='mint-section-blurb'>
                    Serving the cuisine and flavor of our country since 1990, has been the ultimate American dream turned reality for our family.
                    <br/><br/>
                    Cerdas–Salazar Family
                  </div>

                  <img style={{marginTop:'24px'}} src='https://cdn.hngr.co/irazubottom.png' />
                </div>

                <img style={{width:'340px', height:'296px'}} src='https://cdn.hngr.co/irazu-square-food.png' />

              </div>
            </MintPageSection>
          )}

          <Footer />
          <div style={{width:'100%', backgroundColor:'black', height:'100px'}}></div>
        </div>
      )
    }
  }
}
      
export default withRouterAndRef(withWallet(MintPage)) 


const MintPageSection = props => (
  <div> 
    <div className='mint-page-section'>
      {props.children}
    </div>
  </div>
)

const Warning = props => (<svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 0.5C7.44019 0.5 0.5 7.44269 0.5 16C0.5 24.5623 7.44019 31.5 16 31.5C24.5598 31.5 31.5 24.5623 31.5 16C31.5 7.44269 24.5598 0.5 16 0.5ZM16 28.5C9.09175 28.5 3.5 22.9106 3.5 16C3.5 9.09406 9.092 3.5 16 3.5C22.9057 3.5 28.5 9.09194 28.5 16C28.5 22.9081 22.9106 28.5 16 28.5ZM18.625 22C18.625 23.4474 17.4474 24.625 16 24.625C14.5526 24.625 13.375 23.4474 13.375 22C13.375 20.5526 14.5526 19.375 16 19.375C17.4474 19.375 18.625 20.5526 18.625 22ZM13.5394 8.78744L13.9644 17.2874C13.9843 17.6866 14.3138 18 14.7134 18H17.2866C17.6862 18 18.0157 17.6866 18.0356 17.2874L18.4606 8.78744C18.4821 8.35906 18.1405 8 17.7116 8H14.2884C13.8595 8 13.5179 8.35906 13.5394 8.78744Z" fill="black"/>
</svg>)

const Discord = props => (<svg {...props} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2482 1.56348C14.0677 1.00098 12.8189 0.601296 11.5347 0.375C11.3747 0.669524 11.1878 1.06566 11.0589 1.38081C9.67435 1.16878 8.30248 1.16878 6.94333 1.38081C6.81452 1.06573 6.62341 0.669524 6.46197 0.375C5.17651 0.6014 3.92661 1.0021 2.7456 1.56641C0.395469 5.18326 -0.241634 8.71018 0.0768827 12.1872C1.63564 13.3726 3.14624 14.0928 4.63138 14.5641C5.00048 14.0471 5.32675 13.4999 5.60683 12.9279C5.0736 12.7211 4.55954 12.4664 4.07078 12.1666C4.1994 12.0695 4.325 11.9683 4.44738 11.8631C7.40909 13.274 10.6272 13.274 13.5536 11.8631C13.6765 11.9676 13.802 12.0688 13.9301 12.1666C13.4405 12.4671 12.9255 12.7224 12.3912 12.9294C12.6729 13.5037 12.9986 14.0515 13.3667 14.5655C14.8532 14.0943 16.3652 13.3741 17.924 12.1872C18.2978 8.1565 17.2856 4.66189 15.2482 1.56341V1.56348ZM6.01036 10.0489C5.12125 10.0489 4.39211 9.20356 4.39211 8.17419C4.39211 7.14482 5.10571 6.29806 6.01036 6.29806C6.91507 6.29806 7.64414 7.14332 7.6286 8.17419C7.63001 9.20356 6.91507 10.0489 6.01036 10.0489ZM11.9905 10.0489C11.1014 10.0489 10.3723 9.20356 10.3723 8.17419C10.3723 7.14482 11.0859 6.29806 11.9905 6.29806C12.8952 6.29806 13.6243 7.14332 13.6088 8.17419C13.6088 9.20356 12.8952 10.0489 11.9905 10.0489Z" fill="#39F376"/>
</svg>)

const DiscordBlack = props => (<svg {...props} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2477 2.06348C14.0673 1.50098 12.8184 1.1013 11.5342 0.875C11.3743 1.16952 11.1874 1.56566 11.0585 1.88081C9.67389 1.66878 8.30202 1.66878 6.94288 1.88081C6.81406 1.56573 6.62295 1.16952 6.46152 0.875C5.17606 1.1014 3.92616 1.5021 2.74514 2.06641C0.395012 5.68326 -0.242091 9.21018 0.076425 12.6872C1.63519 13.8726 3.14578 14.5928 4.63093 15.0641C5.00002 14.5471 5.32629 13.9999 5.60637 13.4279C5.07314 13.2211 4.55909 12.9664 4.07032 12.6666C4.19895 12.5695 4.32454 12.4683 4.44692 12.3631C7.40863 13.774 10.6267 13.774 13.5531 12.3631C13.676 12.4676 13.8016 12.5688 13.9296 12.6666C13.4401 12.9671 12.9251 13.2224 12.3908 13.4294C12.6724 14.0037 12.9981 14.5515 13.3662 15.0655C14.8528 14.5943 16.3648 13.8741 17.9235 12.6872C18.2973 8.6565 17.2851 5.16189 15.2477 2.06341V2.06348ZM6.0099 10.5489C5.12079 10.5489 4.39165 9.70356 4.39165 8.67419C4.39165 7.64482 5.10526 6.79806 6.0099 6.79806C6.91461 6.79806 7.64368 7.64332 7.62814 8.67419C7.62955 9.70356 6.91461 10.5489 6.0099 10.5489ZM11.9901 10.5489C11.101 10.5489 10.3719 9.70356 10.3719 8.67419C10.3719 7.64482 11.0854 6.79806 11.9901 6.79806C12.8948 6.79806 13.6238 7.64332 13.6083 8.67419C13.6083 9.70356 12.8948 10.5489 11.9901 10.5489Z" fill="black"/>
</svg>)
