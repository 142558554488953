import React, { FC, ReactNode, useMemo } from 'react';
import { BrowserRouter,  Route, Switch } from "react-router-dom"
import { render } from "react-dom"
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js'
import LogRocket from 'logrocket';
import TutorialProvider from "./contexts/TutorialContext"
import UserProvider from "./contexts/UserContext"
import TutorialStart from "./TutorialStart"
import Contracts from "./Contracts"
import Error from "./Error"
import Apply from "./Apply"
import Playground from "./Playground"
import ApplyModal from "./ApplyModal"
import Blockchain from "./Blockchain"
import ScrollToTop from "./ScrollToTop"
import Phantom from "./Phanttom"
import LandingPage from "./mint/LandingPage"
import UnsupportedBrowser from "./mint/UnsupportedBrowser"
import Minting from "./mint/Minting"
import Owner from "./mint/Owner"
import HowTo from "./mint/HowTo"
import { isMobile, isChrome } from 'react-device-detect';
import PremintEligibilityHNGR from "./mint/PremintEligibilityHNGR"
import Login from "./login/Login"
import "./App.css"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


// set the API location as a window variable
if (process.env.REACT_APP_FOODCOIN_API_LOCATION) {
  window.API_HOST = process.env.REACT_APP_FOODCOIN_API_LOCATION
}

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

window.subdomain = window.location.host.split(".")[0].toLowerCase()
window.DISCORD_LINK = 'https://discord.gg/2AH3WCuaSP'

class App extends React.Component {

  componentDidMount(){
    if(process.env.REACT_APP_LOGROCKET){
      LogRocket.init(process.env.REACT_APP_LOGROCKET);
    }
  }


  render() {

    if(isMobile ){
      return (
        <div
          style={{
            width:'100%',
            height:'100%',
            "--bright-green":"#39F376",
          }}
        >
          <Switch>
            <Route
              path="/"
              exact
              component={LandingPage}
            />

            <Route
              component={UnsupportedBrowser}
            />

          </Switch>
        </div>
      )
    }

    if(!isMobile && !isChrome){
      return (
        <div
          style={{
            width:'100%',
            height:'100%',
            "--bright-green":"#39F376",
          }}
        >
          <Switch>
            <Route
              path="/"
              exact
              component={LandingPage}
            />

            <Route
              path="/mint/guide/how-to"
              exact
              component={HowTo}
            />

            <Route
              component={UnsupportedBrowser}
            />

          </Switch>
        </div>
      )
    }




    return (
      <div
        style={{
          width:'100%',
          height:'100%',
          "--bright-green":"#39F376",
        }}
      >
        <Route
          path="/"
          exact
          component={LandingPage}
        />


        <Route
          path="/mint/guide/how-to"
          exact
          component={HowTo}
        />

        <Route
          path="/error-test"
          exact
          component={Error}
        />

        <Route
          path="/minting"
          exact
          component={Minting}
        />

        <Route
          path="/owner"
          exact
          component={Owner}
        />

        <Route
          path="/mint/premint-eligibility-ordering"
          exact
          component={PremintEligibilityHNGR}
        />

        <Route
          path='/unsupported'
          exact
          component={UnsupportedBrowser}
        />

      </div>
    )
  }
}


const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter({ network })
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={false}>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};




render(
  <BrowserRouter>
    <TutorialProvider>
      <UserProvider>
        <Context>
          <ScrollToTop />
          <App/>
        </Context>
      </UserProvider>
    </TutorialProvider>
  </BrowserRouter>
  ,
  document.getElementById("root")
)

