//takes 1 and returns monday.... 7 and returns sunday
var detectWallet = function(){
    if(window.solana && window.solana.isPhantom){
      return 'phantom'
    }    
    
    if(window.solana && !window.solana.isPhantom){
      if(window.solana.isSolflare){
        return 'solflare'
      } else{
        return 'window.solana'
      }    
    }    

    if(window.solflare){
      return 'solflare'
    } 
    return false 
}

module.exports = {
  detectWallet:detectWallet
}
