import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import Countdown from 'react-countdown';
import WalletButton from "../WalletButton"
import withRouterAndRef from "../withRouterAndRef"
import "./Login.css"

const Api = require ("../api")

class LoginPage extends Component {
  
  constructor(props){
    super(props)
    this.state = {
    }
  }
  
  sampleApiCallFunction(){
    Api.callApi({
      endpoint:'/api/mint-info/'+this.mint_url,
      method:'post',
      data:{key:'value'},
      success:(resp)=>{
        let a = resp.scott
      },
      failure:(err)=>{
        // this close gets called on a non 200 response or if an exception
        // occurs in the success block
      }
    })
  }

  submit(){
    alert(this.state.email)
    alert(this.state.password)
  }

  oauth(type){
    alert(type)
    return
    Api.callApi({
      endpoint:'/api/toms-login-oauth-code',
      method:'post',
      data:{key:'value'},
      success:(resp)=>{
        this.props.user.login(resp.user)
      },
      failure:(err)=>{
        // this close gets called on a non 200 response or if an exception
        // occurs in the success block
      }
    })

  }


  render(){
    return (
      <div className='login-page'>
        <div onClick={()=>this.oauth('google')} className='login-button'>Google</div>
        <div onClick={()=>this.oauth('apple')} className='login-button'>Apple</div>
        <div onClick={()=>this.oauth('facebook')} className='login-button'>Facebook</div>
        <br/><br/>

        <div> Email </div>
        <input 
          placeholder='email'
          value={this.state.email}
          onChange={(e)=>this.setState({email:e.target.value})}
        />

        <div> Password </div>
        <input 
          value={this.state.password}
          onChange={(e)=>this.setState({password:e.target.value})}
        />

        <div className='login-submit' onClick={()=>this.submit()}>
          Submit
        </div>

        <br/><br/>

        <div 
          onClick={()=>this.props.history.push('/forgot-password')}
          style={{width:'150px', padding:'10px', textAlign:'center', border:'1px solid black'}}
        >
          Forgot Password
        </div>


      </div>
    )
  }
}
      
export default UserContextHOC(withRouterAndRef(LoginPage)) 
