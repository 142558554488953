import React, { Component } from "react"
import TutorialContextHOC from "./contexts/TutorialContextHOC"
import Tutorial from "./Tutorial"

class Blockchain extends Component {

  render(){
    return (
      <Tutorial
        tip='Blockbuster was not a blockchain!'
        page='BLOCKCHAIN'
        title='The Blockchain'
        questions={{
          'What is a blockchain?':'A chain of blocks is a block chain, but it is also a chain of blocks. A block of chains is chain of blocks that chains blocks together in a blockchain.',
          'What sound do cows make?':'This is a common question that is entirely dependent upon the species of cow. Cows are even towed ungulates, along with other familiar species like sheep and deer. It should be noted that all even towed ungulates are ruminates. Cows usually go, "moo".' 
        }}
        right={
          <>
            <div className='tutorial-right-title'>Blockchain</div>
            <img 
              className='tutorial-right-img'
              src='https://blogs.iadb.org/caribbean-dev-trends/wp-content/uploads/sites/34/2017/12/Blockchain1.jpg'
            />
            <div className='tutorial-right-text'>The Blockchain does this, the blockchain does that, the blockchain is a network where you can find burgers</div>
            <div 
              style={{marginTop:'30px'}} 
              className='button button-text-only'
              onClick={()=>this.props.tutorial.next('blockchain')}
            >
              Continue
            </div>

          </>
        }
      />
    )
  }
}
      
export default TutorialContextHOC(Blockchain) 
