import withRouterAndRef from "../withRouterAndRef"
import WalletButton from "./WalletButton"
import { useWallet } from '@solana/wallet-adapter-react'
import classnames from "classnames"
import "./Header.css"

const detectWallet = require ("./detectWallet")

const Header= props => {

  const FAT = window.subdomain.indexOf('fat') > -1

  return (
    <>
      <div  className='mint-header'>
        <div className='mint-page-content mint-header-inner'>
          <div className='mint-header-left'>
            {props.supperclubOnly ? (
              <img 
                style={{height:'50px', cursor:'pointer'}} 
                src='https://cdn.hngr.co/supperclub-header.gif' 
                onClick={()=>{
                  props.history.push("/")
                }}
              />
            ) : (
              <>
                {FAT ? (
                  <img 
                    style={{height:'50px', cursor:'pointer'}} 
                    src='https://cdn.hngr.co/supperclub-fat-logo.gif' 
                    onClick={()=>{
                      if(props.hardLink) window.location.href='/'
                      if(props.link) props.history.push(props.link)
                    }}
                  />
   
                ) : (
                  <img 
                    style={{height:'50px', cursor:'pointer'}} 
                    src='https://cdn.hngr.co/Irazu-Supper-Club-Logo.gif' 
                    onClick={()=>{
                      if(props.link) props.history.push(props.link)
                    }}
                  />
                )}
              </>
            )}

          </div>

          <div className='mint-header-right'>
            {!props.hideAbout && (
              <>
                {props.about ? (
                  <div 
                    className='mint-header-link'
                    onClick={()=>{
                      if(props.about) props.about()
                    }}
                  >
                    About
                  </div>
                ) : (
                  <a
                    className='mint-header-link'
                    href='/?anchor=about'
                  >
                    About
                  </a>
                )}
              </>
            )}
            {!props.hideHowTo && (
              <>
                {props.howTo ? (
                  <div 
                    className='mint-header-link'
                    onClick={props.howTo}
                  >
                    How to Mint
                  </div>
                ) : (
                  <a
                    className='mint-header-link'
                    href='/?anchor=howto'
                  >
                    How to Mint
                  </a>
                )}
              </>
            )}

             
             <a
              style={{ textDecoration:'none'}} 
              className='mint-header-link'
              target='_blank'
              href='https://www.supperclub.xyz/contact'
            >
              Contact Us
            </a>       

            <a href='https://twitter.com/SupperclubXyz' target='_blank'>
              <Twitter className='mint-header-link'/>
            </a>
            <a href={window.DISCORD_LINK} target='_blank'>
              <Discord className='mint-header-link'/>
            </a>

            {!props.hideWallet && (<WalletButton header={true} walletDetected={detectWallet.detectWallet()} />)}
          </div>
        </div>
      </div>
      <div style={{width:'100%', height:'100px', background:'black'}}></div>
    </>
  )
}




export default withRouterAndRef(Header) 


const Twitter = props =>(
<svg {...props} width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 2.93536C23.1013 3.34175 22.148 3.6085 21.1719 3.72675C22.1887 3.10474 22.9693 2.11976 23.3369 0.946173C22.3704 1.53161 21.3129 1.94409 20.2103 2.16579C19.312 1.18904 18.0324 0.578613 16.6162 0.578613C13.8968 0.578613 11.6921 2.82895 11.6921 5.60445C11.6921 5.99842 11.7357 6.38196 11.8196 6.7499C7.72744 6.54024 4.09931 4.53938 1.67072 1.49861C1.24697 2.24091 1.00416 3.10435 1.00416 4.02531C1.00416 5.76904 1.8735 7.30732 3.19462 8.20866C2.4127 8.18366 1.64798 7.96809 0.964313 7.57995C0.964031 7.601 0.964031 7.62206 0.964031 7.6432C0.964031 10.0783 2.66128 12.1097 4.91372 12.5714C4.18864 12.7727 3.42809 12.8022 2.69016 12.6576C3.31669 14.6543 5.13516 16.1073 7.28972 16.148C5.60456 17.4959 3.48141 18.2995 1.17459 18.2995C0.777094 18.2995 0.385219 18.2756 0 18.2292C2.17903 19.6553 4.76719 20.4873 7.54781 20.4873C16.6047 20.4873 21.5573 12.8288 21.5573 6.18732C21.5573 5.96933 21.5526 5.75258 21.543 5.53708C22.507 4.82577 23.339 3.94475 24 2.93536Z" fill="#BBBBBB"/>
</svg>
)

const Discord = props => (<svg {...props} width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3308 1.58464C18.7568 0.834636 17.0917 0.301727 15.3795 0C15.1662 0.392698 14.917 0.920879 14.7451 1.34108C12.899 1.05837 11.0698 1.05837 9.25766 1.34108C9.08591 0.920975 8.83109 0.392698 8.61584 0C6.9019 0.301866 5.23536 0.836133 3.66068 1.58855C0.52717 6.41102 -0.3223 11.1136 0.102388 15.7496C2.18074 17.3302 4.19487 18.2904 6.17506 18.9187C6.66718 18.2295 7.10221 17.4998 7.47565 16.7372C6.76468 16.4615 6.07927 16.1218 5.42759 15.7221C5.59908 15.5926 5.76654 15.4577 5.92971 15.3175C9.87866 17.1986 14.1694 17.1986 18.0713 15.3175C18.2352 15.4568 18.4026 15.5917 18.5733 15.7221C17.9206 16.1229 17.2339 16.4633 16.5215 16.7392C16.8971 17.5049 17.3313 18.2353 17.8221 18.9207C19.8042 18.2924 21.8202 17.3322 23.8985 15.7496C24.3969 10.3753 23.0473 5.71585 20.3308 1.58454V1.58464ZM8.01369 12.8985C6.82821 12.8985 5.85603 11.7714 5.85603 10.3989C5.85603 9.02643 6.8075 7.89741 8.01369 7.89741C9.21997 7.89741 10.1921 9.02443 10.1713 10.3989C10.1732 11.7714 9.21997 12.8985 8.01369 12.8985ZM15.9872 12.8985C14.8018 12.8985 13.8297 11.7714 13.8297 10.3989C13.8297 9.02643 14.781 7.89741 15.9872 7.89741C17.1935 7.89741 18.1656 9.02443 18.1449 10.3989C18.1449 11.7714 17.1935 12.8985 15.9872 12.8985Z" fill="#BBBBBB"/>
</svg>)
