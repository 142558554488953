import React, { Component } from 'react';
import withRouterAndRef from "../withRouterAndRef"

export const UserContext = React.createContext()

class UserProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      login:(user)=>this.login(user),
      user:null
    }
  }

  login(user){
    this.setState({user:user})
  }
 
  // if the User is not logged in, render the <Login />
  render() {
    return  (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default withRouterAndRef(UserProvider);
