import React, { Component, useMemo } from "react"
import Waves from "./svg/Waves"
import { WalletModalProvider, WalletMultiButton, WalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

require('@solana/wallet-adapter-react-ui/styles.css');


const WalletButton = (props) => {

  const { wallet, connect, connecting, connected } = useWallet();

  const content = useMemo(() => {
      if (connecting) return 'Connecting ...';
      if (connected) return 'Connected';
      if (wallet) return 'Connect';
      return 'Connect Wallet';
  }, [connecting, connected, wallet]);


  return (
    <WalletMultiButton>
      <div className='button'>
        {content}
        <Waves/>
      </div>
    </WalletMultiButton>
  )

}

export default WalletButton
