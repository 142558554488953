import React, { Component } from "react"
import Header from "./Header"
import withWallet from "../withWallet"
import classnames from "classnames"
import withRouterAndRef from "../withRouterAndRef"
import WalletButton from "./WalletButton"
import "./Minting.css"
import "./Owner.css"
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { Connection as MPConnection, programs } from '@metaplex/js'
import localforage from "localforage"
const { metadata: { Metadata } } = programs;
const Api = require ("../api")



class OwnerPage extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      FAT:window.subdomain.indexOf('fat') > -1,
      publicKey:this.props.publicKey
    }
    if(this.props.publicKey){
      let str = this.props.publicKey.toString()
      let address = str.substr(0,4) + '...' + str.substr(str.length-4, str.length)
      this.state['ownedBy'] = address
    }
    window.own = this
  }

  componentDidMount(){
    if(!this.props.publicKey) this.props.history.replace('/')
    this.loadMintInfo() 
  }

  componentDidUpdate(prevProps, prevState){
    if( 
      this.props.publicKey  &&  
      this.state.publicKey &&  
      this.props.publicKey.toString() !== this.state.publicKey.toString()
    ){  
      this.setState({publicKey:this.props.publicKey})
    }   

    if( 
      this.props.publicKey &&
      !this.state.publicKey 
    ){  
      this.setState({publicKey:this.props.publicKey})
    }   
  }

  loadMintInfo(){ 
    Api.callApi({
      endpoint:'/api/mint-info/'+window.subdomain,
      method:'get',
      success:(resp)=>{
        if(resp.error){
          this.setState({error:resp.error})
        } else {
          this.setState({
            name:resp.name,
            mint_date_str:resp.date_str,
            premint_date_str:resp.premint_date_str,
            premint_date_str_at:resp.premint_date_str_at,
            ms:resp.ms,
            mint_open:resp.mint_open,
            premintOpen:resp.premint_open,
            minted:resp.minted,
            limit:resp.limit,
          }, ()=>{
            this.checkForOwner()
            setTimeout(()=>this.setState({loading:false}), 3000)
          })
        }
      },
      failure:(err)=>{
        alert('error')
      }
    })
  }

  recordHamburglar(){
    localforage
      .setItem('hamburglar', this.state.publicKey.toString())
      .then(function (value) {console.log("hamburglar set")})
      .catch(function (err) {
        console.error("local forage saving error", err)
      })
  }


 async checkForOwner(){
    console.log("check for owner....")
    if(this.state.nftName) return

    let connection
    let accounts
    let success = false
    try {
      connection = new solanaWeb3.Connection(
      'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/', 'confirmed')
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    }
    catch (err){
      console.log("EXCEPTIOPN ERROR", err)
      connection = new solanaWeb3.Connection(
      'https://nd-135-906-691.p2pify.com/58f40b25ff76a90476de9023bd163e25', 'confirmed')
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    } finally {
      if(!success){
        setTimeout(()=>this.checkForOwner, 1000)
        return
      }
    }

    let mints = []
    for(let i in accounts){
      let amt = accounts[i].account.data['parsed']['info']['tokenAmount']['uiAmount']
      if(amt === 1){
        mints.push(accounts[i].account.data['parsed']['info']['mint'])
      }
    }

    Api.callApi({
      endpoint:"/fcsc/check-if-own-nft",
      method:'post',
      data:{
        mints:mints,
        mint_url:window.subdomain
      },
      success:(resp)=>{
         if(resp.owner){
          this.setState({
            nftName:resp.name,
            nftAddress:resp.nft_address,
            nftNumber:resp.number
          }, ()=>this.recordHamburglar())
        }
      },
      failure:(err)=>{
        alert('Error verifying NFT ownership')
      }
    })

    setTimeout(()=>this.checkForOwner(), 1000)
  }


  render() {
   if(this.state.loading || !this.state.nftName){
      return (
        <div className='loading-page'>
          <img src='https://cdn.hngr.co/burger-loader.gif' />
        </div>
      )
    }

    return (
      <div className='mint-page '>
        <Header hardLink={true} hideAbout={true} />

        <div className='premint-outer-container'>
          <div className='premint-ordering-content'>
           
            <div className='premint-ordering-left' style={{position:'relative'}}>
              <div className='premint-right-tags'>
                <div className='premint-tag premint-tag-green'>
                  <div>MINT SUCCESSFUL</div>
                </div>

              </div>

              <div className='premint-ordering-title'>
                IT'S YOURS!
              </div>
              

              {this.state.FAT ? (
                <>
                  <div className='mint-page-landing-blrb'>
                    Congratulations! You now own 1 of 500 Fatburger x Supperclub NFTs!
                    <br/><br/>
                    What's next? To start, visit <a href='https://fatburger.com' target='_blank'>Fatburger.com</a> and unlock your FREE Original Fatburger!
                  </div>

                  <div style={{display:'flex'}}>
                    <a 
                      className='generic-green-button'
                      target='_blank'
                      href='https://fatburger-locations.hngr.co/'
                    >
                      <div>Find Your Fatburger</div>
                    </a>

                    <a className='mint-section-black-button' target='_blank' href={window.DISCORD_LINK} style={{marginLeft:'16px'}}>
                      <div>Join Our Discord</div>
                      <Discord style={{marginLeft:'8px'}}/>
                    </a>
                  </div>

                  <div className='perks-are-redeemable'>
                    Perks are redeemable on June 1, 2022
                  </div>

                  {/*
                  <div className='mint-success-totast'>
                    <GreenCheckCircle/>
                    <div style={{marginLeft:'16px'}}>
                      Mint Successful!
                    </div>
                  </div>
                  */}

                </>
 
              ) : (
                <>
                  <div className='mint-page-landing-blrb'>
                    Congratulations! You now own 1 of 100 Irazu x Supper Club NFTs! What's next? To start, place an online order at <a href='https://irazu.hngr.co' target='_blank' className='green-font'>irazuchicago.com</a> and unlock your FREE SHAKE!!
                  </div>
                  <div style={{display:'flex'}}>
                    <a 
                      className='generic-green-button'
                      target='_blank'
                      href='https://irazu.hngr.co'
                    >
                      <div>Use My Perks</div>
                    </a>
                  </div>
                </>
            )}
            
            </div>

            
            <div className='owner-right-side'>

              {this.state.FAT ? (
               <img 
                  src='https://cdn.hngr.co/fat-cropped.gif' 
                  className='owner-img'
                />
              ) : (
                <img 
                  src='https://cdn.hngr.co/irazu_cropped_mint.gif' 
                  className='owner-img'
                />
              )}

              <div className='owner-number'>
                #{this.state.nftNumber} of {this.state.limit}
              </div>
              <div className='owner-nft-name'>
                {this.state.nftName}
              </div>
              <div className='owner-right-bottom-row'>
                <div className='owner-owned-by'>
                  Owned by {this.state.ownedBy}
                </div>
                <a 
                  className='owner-solscan'
                  target='_blank'
                  href={`https://solscan.io/token/${this.state.nftAddress}`}
                >
                  View On Blockchain
                </a>
                
              </div>
            </div>
 


         </div> {/* premint-ordering-content */}
        </div> {/* mint-page-content */ }
      </div>

    )
  }
}
      
export default withRouterAndRef(withWallet(OwnerPage)) 

const Discord = props => (<svg {...props} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2482 1.56348C14.0677 1.00098 12.8189 0.601296 11.5347 0.375C11.3747 0.669524 11.1878 1.06566 11.0589 1.38081C9.67435 1.16878 8.30248 1.16878 6.94333 1.38081C6.81452 1.06573 6.62341 0.669524 6.46197 0.375C5.17651 0.6014 3.92661 1.0021 2.7456 1.56641C0.395469 5.18326 -0.241634 8.71018 0.0768827 12.1872C1.63564 13.3726 3.14624 14.0928 4.63138 14.5641C5.00048 14.0471 5.32675 13.4999 5.60683 12.9279C5.0736 12.7211 4.55954 12.4664 4.07078 12.1666C4.1994 12.0695 4.325 11.9683 4.44738 11.8631C7.40909 13.274 10.6272 13.274 13.5536 11.8631C13.6765 11.9676 13.802 12.0688 13.9301 12.1666C13.4405 12.4671 12.9255 12.7224 12.3912 12.9294C12.6729 13.5037 12.9986 14.0515 13.3667 14.5655C14.8532 14.0943 16.3652 13.3741 17.924 12.1872C18.2978 8.1565 17.2856 4.66189 15.2482 1.56341V1.56348ZM6.01036 10.0489C5.12125 10.0489 4.39211 9.20356 4.39211 8.17419C4.39211 7.14482 5.10571 6.29806 6.01036 6.29806C6.91507 6.29806 7.64414 7.14332 7.6286 8.17419C7.63001 9.20356 6.91507 10.0489 6.01036 10.0489ZM11.9905 10.0489C11.1014 10.0489 10.3723 9.20356 10.3723 8.17419C10.3723 7.14482 11.0859 6.29806 11.9905 6.29806C12.8952 6.29806 13.6243 7.14332 13.6088 8.17419C13.6088 9.20356 12.8952 10.0489 11.9905 10.0489Z" fill="#39F376"/>
</svg>)


const GreenCheckCircle = props => (<svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 0.5C7.43956 0.5 0.5 7.43956 0.5 16C0.5 24.5604 7.43956 31.5 16 31.5C24.5604 31.5 31.5 24.5604 31.5 16C31.5 7.43956 24.5604 0.5 16 0.5ZM16 3.5C22.9083 3.5 28.5 9.09069 28.5 16C28.5 22.9083 22.9093 28.5 16 28.5C9.09175 28.5 3.5 22.9093 3.5 16C3.5 9.09175 9.09069 3.5 16 3.5ZM24.7628 11.6417L23.3543 10.2218C23.0626 9.92775 22.5877 9.92581 22.2936 10.2176L13.4591 18.9811L9.72213 15.2138C9.43044 14.9197 8.95556 14.9178 8.6615 15.2094L7.24156 16.6179C6.9475 16.9096 6.94556 17.3845 7.23731 17.6786L12.9111 23.3984C13.2028 23.6924 13.6777 23.6944 13.9718 23.4026L24.7586 12.7024C25.0526 12.4106 25.0544 11.9358 24.7628 11.6417Z" fill="#218947"/>
</svg>
)
