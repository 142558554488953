import React, { Component } from "react"
import Header from "./Header"
import Countdown from 'react-countdown';
import WalletButton from "./WalletButton"
import withWallet from "../withWallet"
import classnames from "classnames"
import withRouterAndRef from "../withRouterAndRef"
import "./LandingPage.css"
import"./PremintOrdering.css"
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { Connection as MPConnection, programs } from '@metaplex/js'
import X from "../svg/X"
import {WalletReadyState } from '@solana/wallet-adapter-base';


const { metadata: { Metadata } } = programs;

const Api = require ("../api")
const detectWallet = require ("./detectWallet")
const METADATA_PROGRAM_ID = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
const METADATA_PREFIX = "metadata";



class PremintEligible extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      orderingConnected:false,
      publicKey:false,
      eligible:null,
      loaded:false,
      walletReadyState:null,
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this._loginWithMethod = this._loginWithMethod.bind(this)
    window.subomain = window.location.host.split(".")[0].toLowerCase() 
    window.pre = this
  }

  componentDidMount(){
    this.loadMintInfo()
    window.addEventListener('mousedown', this.handleClickOutside)
    let params
    let data
    params = new URLSearchParams(window.location.search)
    if(params && params.get('p')){
      data = JSON.parse(atob(params.get('p')))
    }
    if(params && params.get('origin_state')){
      if (params.get('origin_state') == 1){
        this.tryToConnectToWallet()
      }     
    }
    console.log("params", params)
    console.log("data", data)
    if(data && data.userdata && data.userdata.uniq_uuid){
      this.setState({
        userdata:data.userdata,
        orderingConnected:true,
        diner_uuid:data.userdata.uniq_uuid
      })
    }
    this.checkForWallet()
    // check 2 seconds later in case wallet is slow to load or something
    // like that 
    setTimeout(()=>this.checkForWallet(), 2000)
  }

  componentDidUpdate(prevProps, prevState){
    if( 
      this.props.publicKey  &&  
      this.state.publicKey &&  
      this.props.publicKey.toString() !== this.state.publicKey.toString()
    ){  
      this.setState({publicKey:this.props.publicKey})
    }   

    if( 
      this.props.publicKey &&
      !this.state.publicKey 
    ){  
      this.setState({publicKey:this.props.publicKey},()=>this.maybeCheckEligibilty())
    }   

    if(this.state.orderingConnected && !prevState.orderingConnected){
      this.maybeCheckEligibilty()
    }

    if(this.state.publicKey && !this.props.publicKey){
      this.setState({publicKey:null})
    }   

  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  checkForWallet(){
    let wallet = detectWallet.detectWallet()
    this.setState({walletDetected:wallet})
  }

  tryToConnectToWallet(){
    if(this.connectCounter > 40) return
    if(
      window.wallet && 
      (window.wallet.readyState === WalletReadyState.Installed || window.wallet.readyState === WalletReadyState.Loadable)
    ){
      this.props.connectWallet()
    } else{
      this.connectCounter += 1
      setTimeout(()=>this.tryToConnectToWallet(), 100)
    }
  }


  handleClickOutside(e){
    if(!e) return
    if(!e.target) return
    if(!e.target.className) return

    if(!this.state.loginModal && !this.state.phantomModal) {
      return
    }
    if (e.target.className.indexOf('modal-background') > -1){
      this.closeModal()
      this.setState({phantomModal:null})
    }
  }

  loadMintInfo(){ 
    Api.callApi({
      endpoint:'/api/mint-info/'+window.subomain,
      method:'get',
      success:(resp)=>{
        if(resp.error){
          this.setState({error:resp.error})
        } else {
          this.setState({
            loaded:true,
            name:resp.name,
            mint_date_str:resp.date_str,
            premint_date_str:resp.premint_date_str,
            premint_date_str_at:resp.premint_date_str_at,
            ms:resp.ms,
            mint_open:resp.mint_open,
            premintOpen:resp.premint_open,
            minted:resp.minted,
            limit:resp.limit,
            loading:false
          })
        }
      },
      failure:(err)=>{
        alert('error')
      }
    })
  }



  /* _loginWithMethod - bounce off to the backend login
     endpoint but with the method you suggest want */
  _loginWithMethod(platform) {
    const subDomain = encodeURIComponent(window.location.origin)
    let wallet = this.state.publicKey ? 1 : 0
    let getParams = {'platform': platform,
      'redirect_uri': window.location.href.split('?')[0],
      'origin_state': wallet, 
      'subdomain': subDomain}

    let url = new URL(window.API_HOST + '/api/login/v2/oauth')
    url.search = new URLSearchParams(getParams)
    const urlString = url.toString()
    window.location.href = urlString
  }

  closeModal(){
    this.setState({loginModal:false, loginType:null})
  }

  numStepsCompleted(){
    let cnt = 0
    if(this.state.orderingConnected) cnt += 1
    if(this.state.publicKey) cnt += 1
    return cnt
  }

  accountsConnected(){
    if(this.state.publicKey && this.state.diner_uuid) return true
    else return false
  }


  emailLogin(){
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/api/login/v2/email',
      method:'post',
      data:{email:this.state.email, password:this.state.password},
      success:(resp)=>{
        if(resp.success == '0') {
          alert('The password or email account you entered is incorrect.')
          this.setState({emailLoginFailure:true, loading:false})
          return
        } else{
          this.setState({
            loading:false,
            loginModal:false,
            userdata:resp.userdata,
            orderingConnected:true,
            diner_uuid:resp.userdata.uniq_uuid
          })
        }
      },
      failure:(err)=>{
        alert('An unknown error occured')
        this.setState({loading:false})
      }
    })
  }


  maybeCheckEligibilty(){
    if(this.state.publicKey && this.state.orderingConnected){
      this.checkEligibility()
    }
  }

  checkEligibility(){
    this.setState({checking:true})
    Api.callApi({
      endpoint:'/fcsc/check-eligibility',
      method:'post',
      data:{
        diner_uuid:this.state.diner_uuid, 
        wallet_address:this.state.publicKey.toString(),
        mint_url:window.subomain
      },
      success:(resp)=>{
        this.checking = false
        if(resp.eligible){
          this.setState({eligible:true, checking:false})
        } else{
          this.setState({eligible:false, checking:false})
        }
      },
      failure:(err)=>{
        this.checking = false
        alert('An unknown error occured')
        this.setState({loading:false, checking:false})
      }
    })

  }

  renderPhantomModal(){
    return(
      <div className='modal-background hngr-login-modal-background'>
        <div className='modal-content phantom-modal-content'>
          <X
            className='modal-x-close'
            onClick={()=>this.setState({phantomModal:null})}
          />

          <img 
            src='https://cdn.hngr.co/phantomincon.png'
            style={{width:'80px', height:'80px'}}
          />

          <div style={{width:'100%'}}>
            <div className='phantom-modal-title'>
              Download the Phantom Extension
            </div>
          </div>
          
          <div style={{width:'100%'}}>
            <div className='phantom-modal-blurb'>
              Phantom makes it safe & easy for you to store, buy, send receive, swap tokens and collect NFTs on the Solana blockchain.
            </div>
          </div>
          
          <a 
            className='phantom-modal-button'
            href='https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa'
          >
            <div>Download Phantom</div> 
          </a>

          <div
            className='phantom-modal-button-black'
            onClick={()=>this.props.history.push('/mint/guide/how-to')}
          >
            <div>Learn More about Solana</div>
          </div>
        </div>
      </div>
    )
  }




  renderLoginModal(){

    if(this.state.loginType === 'email'){
      return (
        <div className='modal-background hngr-login-modal-background'>
          <div className='modal-content hngr-login-modal-content'>
            <X
              className='modal-x-close'
              onClick={()=>this.closeModal()}
            />
            <div className='hngr-login-modal-title' style={{marginBottom:'30px'}}>
              Sign In with Email
            </div>

            <div className='login-input-label'>Email</div>
            <input
              className='login-input'
              placeholder='Enter your email'
              value={this.state.email}
              onChange={ (e) =>this.setState({email:e.target.value})}
            />

            <div style={{marginTop:'25px'}} className='login-input-label'>Password</div>
            <input
              style={{marginBottom:'35px'}}
              className='login-input'
              placeholder='Password'
              type='password'
              value={this.state.password}
              onChange={ (e) =>this.setState({password:e.target.value})}
            />

            <div className='forgot-password-sign-in-row'>
              <a
                href='https://irazu.hngr.co/menu/anon_password_reset'
                target='_blank'
                className='forgot-password'
              >
                Forgot Password?
              </a>


              <div 
                className='signin-email-button'
                onClick={()=>this.emailLogin()}
              >
                <div>Sign In</div>
              </div>


            </div>

            <div className='or-row'>
              <div className='or-left'></div>
              <div> or </div>
              <div className='or-right'></div>
            </div>

            <div className='oauth-row'>
              <AppleCircle onClick={()=>this._loginWithMethod('apple')}  />
              <FacebookCircle onClick={()=>this._loginWithMethod('facebook')} />
              <GoogleCircle onClick={()=>this._loginWithMethod('google')} />
            </div>

          </div>
        </div>
      )

    } else {
      return(
        <div className='modal-background hngr-login-modal-background'>
          <div className='modal-content hngr-login-modal-content'>
            <X
              className='modal-x-close'
              onClick={()=>this.closeModal()}
            />
            <div className='hngr-login-modal-title'>
              Connect Ordering
            </div>

            <div className='hngr-login-modal-blurb'>
              Connect your account from irazu.hngr.co
            </div>

            <div className='oauth-row'>
              <AppleCircle onClick={()=>this._loginWithMethod('apple')}  />
              <FacebookCircle onClick={()=>this._loginWithMethod('facebook')} />
              <GoogleCircle onClick={()=>this._loginWithMethod('google')} />
            </div>

            <div className='or-row'>
              <div className='or-left'></div>
              <div> or </div>
              <div className='or-right'></div>
            </div>

            <div 
              className='signin-with-email'
              onClick={()=>this.setState({loginType:'email'})}
            >
              Sign In with Email
            </div>
          </div>
        </div>
      )
    }
  }

  renderOrderingConnection(){
    if(this.state.orderingConnected){
      
      // we need to get their email, get everything after the @ and the first
      // letter
      let email = this.state.userdata.email
      let email_str = email.substr(0,1) + '...' + email.substr(email.indexOf('@'), email.length-1)

      if(this.state.eligible){
        return (
          <div 
            style={{marginTop:'20px'}} 
            className={classnames('premint-requirement-box premint-requirement-box-connected')}
          >
            <div className='premint-requirement-box-left premint-requirement-box-left-connected'>
              <CheckCircle />
              <div>Ordering Connected</div>
            </div>

            <div 
              className='premint-requirement-box-connected-button'
            >
              <div>{email_str}</div>
              <XCircle 
                onClick={()=>this.setState({
                  eligible:null,
                  orderingConnected:false,
                  userData:null,
                  diner_uuid:null
                })}
                className='connected-x'
              />
            </div>
          </div>
        )
      } else {
        return (
          <div 
            style={{marginTop:'20px'}} 
            className={classnames('premint-requirement-box premint-requirement-box-fail')}
          >
            <div className='premint-requirement-box-left premint-requirement-box-left-fail'>
              <RedX/>
              <div>Account Ineligible</div>
            </div>

            <a 
              className='premint-requirement-box-red-order'
              target='_blank'
              href='https://irazu.hngr.co'
            >
              <div>Order Irazu for an Allow List Spot</div>
            </a>
          </div>
        )
      }
    } 
    
    else return (
      <div 
        style={{marginTop:'20px'}} 
        className='premint-requirement-box premint-requirement-box-unconnected'
        onClick={()=>this.setState({loginModal:true})}
      >
        <div className='premint-requirement-box-left'>
          <Fork style={{height:'24px', width:'24px', marginRight:'16px'}}/>
          <div>Connect Ordering Account</div>
        </div>

        <div 
          className='premint-requirement-box-connect'
        >
          <div>Connect</div>
        </div>
      </div>
    )
  }


  renderWalletConnection(){
    if(this.state.publicKey){
      let str = this.state.publicKey.toString()
      let address = str.substr(0,4) + '...' + str.substr(str.length-4, str.length)
      return (
        <div style={{marginTop:'20px'}} className='premint-requirement-box premint-requirement-box-connected'>
          <div className='premint-requirement-box-left premint-requirement-box-left-connected'>
            <CheckCircle />
            <div>Wallet Connected</div>
          </div>

          <div 
            className='premint-requirement-box-connected-button'
          >
            <div>{address}</div>
            <XCircle 
              onClick={()=>{
                this.props.disconnectWallet(); 
                this.setState({eligible:null})
              }}
              className='connected-x'
            />
          </div>
        </div>
      )
    } else {
      return (
        <div 
          style={{marginTop:'20px'}} 
          className='premint-requirement-box  premint-requirement-box-unconnected'
          onClick={()=>{
            if(this.state.walletDetected){
              document.getElementById('premint-connect-button').click()
            } else {
              this.setState({phantomModal:true})
            }
          }}
        >
          <div className='premint-requirement-box-left'>
            <img style={{marginRight:'16px'}} src='https://cdn.hngr.co/solana-sol-logo%203.png'/>
            <div>Connect Solana Wallet</div>
          </div>

          <div className='premint-wallet-container'>
            {this.state.walletDetected ? (
              <div><WalletButton id2='premint-connect-button' premintEligibility={true} connectOneWord/></div>
            ) : (
              <div className='premint-requirement-box-connect'>
                <div>Connect</div>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className='mint-page '>
        <div className='mint-page-content'> 
          <Header hideAbout={true} link='/'/>
        </div>

        <div className='premint-outer-container'>


          {this.state.loginModal && this.renderLoginModal()}
          {this.state.phantomModal && this.renderPhantomModal()}

          <div className='premint-ordering-content'>
            
            
            <div className='premint-ordering-left'>

              {this.state.loaded === true && (
              <>


              <div className='premint-right-tags'>
                <div className='premint-tag premint-tag-green' style={{marginRight:'20px'}}>
                  <div>{this.state.minted}/{this.state.limit} Minted</div>
                </div>

                <div className='premint-tag premint-tag-grey'>
                  <div>PRICE: FREE MINT</div>
                </div>
              </div>

              {this.state.eligible === null && (
                <>
                  <div className='premint-ordering-title'>
                    PRE MINT ACCESS
                  </div>

                  <div className='premint-green-subtitle'>
                    You must meet the following requirements:
                  </div>
                 
                  {this.renderWalletConnection()}
                  {this.renderOrderingConnection()}

                  
                  <div className='premint-bottom-status'>
                    {(this.state.checking) ? (
                      <>
                        <img 
                          src='https://cdn.hngr.co/premintspinner.gif' 
                          style={{marginRight:'16px', width:'24px', height:'24px'}}
                        />
                        <div>Verifying elgibility...</div> 
                      </>
                    ) : (
                      <div>Connect account &amp; wallet to verify</div>
                    )}
                  </div>
                

                  {/*
                  <div style={{display:'flex'}}>
                    {!this.accountsConnected() ? (
                      <div className='eligible-button-disabled'>
                        <div>See if I’m Eligble → </div>
                      </div>
                    ) : (
                      <div 
                        className='eligible-button-enabled'
                        onClick={()=>this.checkEligibility()}
                      >
                        <div>See if I’m Eligble → </div>
                      </div>
                    )}
                  </div>
                  */}


                </>
              )}

              {this.state.eligible && (
                <>
                  <div className='premint-ordering-title'>
                    YOU ARE IN!
                  </div>
                   
                  <div className='premint-green-subtitle'>
                    {this.state.premintOpen ? ( 
                      <>Pre-mint access is available now</>
                    ) : (
                      <>Pre-mint opens {this.state.premint_date_str_at}</>
                    )}
                  </div>

                  <div className='premint-blurb'>
                    {this.state.premintOpen ? (
                      <>Congrats! You qualified for pre-mint access to the Irazu x Supper Club NFT. Click below to mint your NFT and get first dibs on the most edible NFT in the metaverse.</>
                    ) : (
                      <>Congrats! You qualified for pre-mint access to the Irazu x Supper Club NFT. Come back here on {this.state.premint_date_str_at} to get first dibs on the most edible NFT in the metaverse.</>
                    )}
                  </div>

                  {this.renderWalletConnection()}
                  {this.renderOrderingConnection()}


                  {this.state.premintOpen ? ( 
                    <div style={{display:'flex'}}>
                      <div
                        className='eligible-button-enabled'
                        onClick={()=>this.props.history.push(`/minting`)}
                      >
                        <div>Take Me to Mint</div>
                      </div>
                    </div>
                  ) : (
                    <div style={{display:'flex'}}>
                      <a
                        className='eligible-button-enabled'
                        href={window.DISCORD_LINK}
                        target='_blank'
                      >
                        <div>Join Our Discord</div>
                      </a>
                      <div
                        onClick={()=>this.props.history.push('/')}
                        style={{marginLeft:'20px'}}
                        className='premint-black-button'
                      >
                        <div>Back to Home</div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {this.state.eligible === false && (
                <>
                  <div className='premint-ordering-title'>
                    ACCESS DENIED
                  </div>

                  <div className='premint-green-subtitle'>
                    Pre-mint opens {this.state.premint_date_str_at}
                  </div>

                  <div className='premint-blurb'>
                    Bummer. You didn’t qualify for pre-mint access. Order from Irazu before Pre-Mint starts on May 4, or come back on May 5 at 8PM CST during our Public Mint
                  </div>

                  {this.renderWalletConnection()}
                  {this.renderOrderingConnection()}
                  

                  <div style={{display:'flex'}}>


                    {this.state.eligible === false ? (
                      <a
                        className='eligible-button-enabled'
                        href='https://irazu.hngr.co'
                        target='_blank'
                      >
                        <div>Order from Irazu</div>
                      </a>
                     ) : (
                      <a
                        className='eligible-button-enabled'
                        href={window.DISCORD_LINK}
                        target='_blank'
                      >
                        <div>Join Our Discord</div>
                      </a>
                    )}

                   <div
                      onClick={()=>this.props.history.push('/')}
                      style={{marginLeft:'20px'}}
                      className='premint-black-button'
                    >
                      <div>Back to Home</div>
                    </div>
                  </div>
                </>
              )}

            
              </>
              )}
            </div>


            <div className='premint-ordering-img' style={this.state.loaded ? {} : {display:'none'}}>
              <img 
                src='https://cdn.hngr.co/Silhouette-Card-v2.gif'
                className='premint-ordering-img'
            />
            </div>

          </div> {/* premint-ordering-content */}
        </div> {/* mint-page-content */ }
      </div>

    )
  }
}
      
export default withRouterAndRef(withWallet(PremintEligible)) 

const CheckCircle = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.375C5.57967 0.375 0.375 5.57967 0.375 12C0.375 18.4203 5.57967 23.625 12 23.625C18.4203 23.625 23.625 18.4203 23.625 12C23.625 5.57967 18.4203 0.375 12 0.375ZM12 2.625C17.1812 2.625 21.375 6.81802 21.375 12C21.375 17.1812 17.182 21.375 12 21.375C6.81881 21.375 2.625 17.182 2.625 12C2.625 6.81881 6.81802 2.625 12 2.625ZM18.5721 8.73127L17.5157 7.66636C17.2969 7.44581 16.9408 7.44436 16.7202 7.66317L10.0943 14.2358L7.29159 11.4103C7.07283 11.1898 6.71667 11.1883 6.49613 11.4071L5.43117 12.4635C5.21062 12.6822 5.20917 13.0384 5.42798 13.259L9.68334 17.5488C9.90211 17.7693 10.2583 17.7708 10.4788 17.552L18.5689 9.52678C18.7894 9.30797 18.7908 8.95181 18.5721 8.73127Z" fill="#39F376"/>
</svg>)

const Facebook = props => (<svg {...props} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 0.5H1C0.446875 0.5 0 0.946875 0 1.5V24.5C0 25.0531 0.446875 25.5 1 25.5H24C24.5531 25.5 25 25.0531 25 24.5V1.5C25 0.946875 24.5531 0.5 24 0.5ZM21.1125 7.79688H19.1156C17.55 7.79688 17.2469 8.54062 17.2469 9.63437V12.0437H20.9844L20.4969 15.8156H17.2469V25.5H13.35V15.8188H10.0906V12.0437H13.35V9.2625C13.35 6.03437 15.3219 4.275 18.2031 4.275C19.5844 4.275 20.7688 4.37813 21.1156 4.425V7.79688H21.1125Z" fill="white"/>
</svg>)

const FacebookCircle = props => (
  <div  onClick={props.onClick} className='login-circle' style={{backgroundColor:'#1777F2'}}>
    <Facebook/>
  </div>
)

const Google = props => (<svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_414_2490)">
<path d="M7.52108 13.02C8.14492 11.1326 9.34889 9.49042 10.9612 8.32773C12.5736 7.16505 14.5119 6.54125 16.4997 6.54533C18.7531 6.54533 20.7904 7.34533 22.3904 8.65467L27.0464 4C24.2091 1.52667 20.5731 0 16.4997 0C10.1931 0 4.76374 3.59733 2.15308 8.86667L7.52108 13.02Z" fill="#EA4335"/>
<path d="M21.8867 24.0174C20.4334 24.9547 18.5881 25.4547 16.5001 25.4547C14.5202 25.4588 12.5892 24.84 10.9804 23.6859C9.37164 22.5318 8.16667 20.9009 7.53608 19.024L2.14941 23.1134C3.47107 25.7884 5.51644 28.0392 8.05318 29.6101C10.5899 31.181 13.5164 32.009 16.5001 32C20.4107 32 24.1467 30.6094 26.9454 28L21.8881 24.0174H21.8867Z" fill="#34A853"/>
<path d="M26.9453 28C29.872 25.2694 31.772 21.2054 31.772 16C31.772 15.0534 31.6267 14.036 31.4093 13.0907H16.5V19.2734H25.0813C24.6587 21.352 23.5213 22.9614 21.888 24.0174L26.9453 28Z" fill="#4A90E2"/>
<path d="M7.53591 19.024C7.20972 18.0492 7.044 17.028 7.04524 16C7.04524 14.9574 7.21191 13.956 7.52124 13.02L2.15324 8.8667C1.05508 11.0836 0.488983 13.5261 0.499911 16C0.499911 18.56 1.09324 20.9734 2.14924 23.1134L7.53591 19.024Z" fill="#FBBC05"/>
</g>
<defs>
<clipPath id="clip0_414_2490">
<rect width="32" height="32" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
)

const GoogleCircle = props => (
  <div onClick={props.onClick} className='login-circle' style={{backgroundColor:'white'}}>
    <Google/>
  </div>
)

const Apple = props => (<svg {...props} width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.5652 14.797C20.5515 12.5019 21.6124 10.7722 23.7549 9.49675C22.5567 7.81412 20.7438 6.88867 18.3541 6.71032C16.0914 6.53532 13.6159 8.00257 12.7094 8.00257C11.7515 8.00257 9.56094 6.77089 7.83734 6.77089C4.28026 6.82473 0.5 9.55059 0.5 15.0965C0.5 16.7354 0.805579 18.4281 1.41674 20.1713C2.23391 22.4664 5.17983 28.0898 8.25279 27.9989C9.85966 27.9619 10.9961 26.8816 13.0871 26.8816C15.1163 26.8816 16.167 27.9989 17.9592 27.9989C21.0597 27.9552 23.724 22.8433 24.5 20.5415C20.3421 18.6199 20.5652 14.9148 20.5652 14.797ZM16.9567 4.533C18.6974 2.50711 18.5395 0.662955 18.488 0C16.9498 0.0874966 15.1712 1.0264 14.1584 2.18068C13.0425 3.4191 12.3867 4.95029 12.5275 6.67666C14.1893 6.80118 15.7069 5.96323 16.9567 4.533Z" fill="white"/>
</svg>
)

const AppleCircle = props => (
  <div onClick={props.onClick}  className='login-circle' style={{border:'2px solid white'}}>
    <Apple/>
  </div>
)

const Fork = props => (
  <svg {...props} width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99519 0.712565C10.0337 0.932877 10.7692 5.14224 10.7692 6.75005C10.7692 9.2016 9.43269 10.95 7.45673 11.6532L8.07692 22.8141C8.11058 23.4563 7.58654 24 6.92308 24H3.84615C3.1875 24 2.65865 23.4609 2.69231 22.8141L3.3125 11.6532C1.33173 10.95 0 9.19692 0 6.75005C0 5.13755 0.735577 0.932877 0.774038 0.712565C0.927885 -0.238995 2.95192 -0.253057 3.07692 0.764127V7.38286C3.13942 7.54223 3.80288 7.53286 3.84615 7.38286C3.91346 6.19693 4.22596 0.857877 4.23077 0.736003C4.38942 -0.238995 6.37981 -0.238995 6.53365 0.736003C6.54327 0.862565 6.85096 6.19693 6.91827 7.38286C6.96154 7.53286 7.62981 7.54223 7.6875 7.38286V0.764127C7.8125 -0.24837 9.84135 -0.238995 9.99519 0.712565ZM15.726 14.1047L15.0048 22.7813C14.9471 23.4375 15.4808 24 16.1538 24H18.8462C19.4856 24 20 23.4984 20 22.875V1.12506C20 0.506316 19.4856 6.69961e-05 18.8462 6.69961e-05C14.8798 6.69961e-05 8.20192 8.36723 15.726 14.1047Z" fill="white"/>
</svg>
)

const RadioCircle = props => ( 
  <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.5" r="11" stroke="#BBBBBB" stroke-width="2"/>
  </svg>
)

const XCircle = props => (<svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" d="M9 0.28125C4.18359 0.28125 0.28125 4.18359 0.28125 9C0.28125 13.8164 4.18359 17.7187 9 17.7187C13.8164 17.7187 17.7187 13.8164 17.7187 9C17.7187 4.18359 13.8164 0.28125 9 0.28125ZM9 16.0312C5.11523 16.0312 1.96875 12.8848 1.96875 9C1.96875 5.11523 5.11523 1.96875 9 1.96875C12.8848 1.96875 16.0312 5.11523 16.0312 9C16.0312 12.8848 12.8848 16.0312 9 16.0312ZM12.5789 6.81328L10.3922 9L12.5789 11.1867C12.7441 11.352 12.7441 11.6191 12.5789 11.7844L11.7844 12.5789C11.6191 12.7441 11.352 12.7441 11.1867 12.5789L9 10.3922L6.81328 12.5789C6.64805 12.7441 6.38086 12.7441 6.21562 12.5789L5.42109 11.7844C5.25586 11.6191 5.25586 11.352 5.42109 11.1867L7.60781 9L5.42109 6.81328C5.25586 6.64805 5.25586 6.38086 5.42109 6.21562L6.21562 5.42109C6.38086 5.25586 6.64805 5.25586 6.81328 5.42109L9 7.60781L11.1867 5.42109C11.352 5.25586 11.6191 5.25586 11.7844 5.42109L12.5789 6.21562C12.7441 6.38086 12.7441 6.64805 12.5789 6.81328Z" fill="white"/>
</svg>)

const RedX = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM12 21.375C6.82031 21.375 2.625 17.1797 2.625 12C2.625 6.82031 6.82031 2.625 12 2.625C17.1797 2.625 21.375 6.82031 21.375 12C21.375 17.1797 17.1797 21.375 12 21.375ZM16.7719 9.08438L13.8563 12L16.7719 14.9156C16.9922 15.1359 16.9922 15.4922 16.7719 15.7125L15.7125 16.7719C15.4922 16.9922 15.1359 16.9922 14.9156 16.7719L12 13.8563L9.08438 16.7719C8.86406 16.9922 8.50781 16.9922 8.2875 16.7719L7.22812 15.7125C7.00781 15.4922 7.00781 15.1359 7.22812 14.9156L10.1437 12L7.22812 9.08438C7.00781 8.86406 7.00781 8.50781 7.22812 8.2875L8.2875 7.22812C8.50781 7.00781 8.86406 7.00781 9.08438 7.22812L12 10.1437L14.9156 7.22812C15.1359 7.00781 15.4922 7.00781 15.7125 7.22812L16.7719 8.2875C16.9922 8.50781 16.9922 8.86406 16.7719 9.08438Z" fill="#FF0000"/>
</svg>)
