import React, { Component } from "react"
import Header from "./Header"
import "./HowTo.css"


class HowTo extends Component {
  
  render() {
    return (
      <div className='mint-page '>
        <div className='mint-page-content'> 
          <Header supperclubOnly={true} hideAbout={true} hideHowTo={true} />
        </div>
        <div style={{width:'920px'}}>
          <div className='howto-top'>
            <img src='https://cdn.hngr.co/solana-big.png' />
            <div className='howto-title'>
              INTRO TO CRYPTO, NFTS, &amp; MINTING ON SOLANA
            </div>
            <div className='howto-subtitle'>
              Not sure where to start? We can help.
            </div>
          </div>

          <div className='howto-section'>
            <img src='https://cdn.hngr.co/coin-grid.png' className='howto-img' />
            <div className='howto-section-right'>
              <div className='howto-section-title'>
                INTRO
              </div>

              <div className='howto-section-blurb'>
                A cryptocurrency (AKA crypto) is a form of digital asset based on a network that is distributed across a large number of computers. This decentralized structure allows them to exist outside the control of any one large entity. Many cryptocurrencies are based on Blockchain networks, essentially a ledger verified by the network and its participants.
              </div>

              <div className='howto-green-subtitle'>
                What’s the Blockchain?
              </div>

              <div className='howto-section-blurb'>
                A bank or financial institution is typically responsible for maintaining and protecting a ledger of transactional data. We trust our money with them because they have a known reputation. Unfortunately they can charge us fees, penalties if we don’t play by their rules. For the most part, we trust that our money is safe when we place it in a bank.
                <br/><br/>

                The Blockchain is a public ledger where data cannot be changed or removed. The data is stored among several networks in various locations, rather than one central location, making it decentralized.
                <br/><br/>

                Every crypto transaction is stored in a “block”, and all of those blocks are chained together. Manipulating the ledger would manipulate the entire block. The result is a permanent public record of transaction history.
              </div>

            </div>
          </div>


          <div className='howto-section'>
            <img src='https://cdn.hngr.co/hand-grid.png' className='howto-img' />
            <div className='howto-section-right'>
              <div className='howto-section-title'>
                WALLET
              </div>

              <div className='howto-section-blurb'>
                  Unlike a normal wallet, which can hold actual cash, crypto wallets technically don’t store your crypto. Your holdings live on the blockchain, but can only be accessed using a private key - your crypto wallet. <br/><br/>

                  To get started with Solana, you’ll need a wallet. Every wallet has an address associated with it, made up of a long string of numbers and letters. This is your Solana mailing address.
              </div>

              <div className='howto-green-subtitle'>
                How do I get a wallet?
              </div>

              <div className='howto-section-blurb'>
                Making a wallet is free. We recommend our friends at Phantom. Phantom is an app that allows you to create wallets exclusively on the Solana blockchain. 
              </div>
   
              <div className='howto-grey-instruction-box'>
                <div className='howto-instruction-box-title'>
                  Phantom Wallet Set-Up
                </div>

                <div className='howto-instruction-hr'></div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>1</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Download the plugin from  <a href='https://phantom.app/download' target='_blank'>
                        <span className='green-font'>phantom.app/download</span>
                      </a>
                    </div>
                    <div className='howto-instruction-text'>
                      Select your browser and follow instructions to install.
                    </div>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>2</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Select “Create New Wallet”
                    </div>
                    <div className='howto-instruction-text'>
                      Follow the instructions from Phantom and don’t forget to save your Recovery Phrase - you need it to access your account.
                    </div>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>3</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Pin the extension to your browser
                    </div>
                    <div className='howto-instruction-text'>
                      Follow the instructions from Phantom and don’t forget to save your Recovery Phrase - you need it to access your account.
                    </div>
                  </div>
                </div>

              </div> {/* instrution box */} 


              <div className='howto-green-subtitle'>
                How do I add funds?
              </div>

              <div className='howto-section-blurb'>
                Unfortunately, you can’t purchase Solana from your wallet with real money quite yet. You need to go through an exchange, such as Coinbase, to convert US Dollars into Solana. Then, you can send Solana to your Solana Wallet.
              </div>



              <div className='howto-grey-instruction-box'>
                <div className='howto-instruction-box-title'>
                  Adding Funds
                </div>

                <div className='howto-instruction-hr'></div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>1</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Use Coinbase or another Solana listed exchange to deposit your funds
                    </div>
                    <div className='howto-instruction-text'>
                      Create an account and connect your payment info according to the exchange’s instructions. Recommended exchanges:
                    </div>
                    <img src='https://cdn.hngr.co/exchanges.png' style={{marginTop:'8px', marginBottom:'24px'}}/>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>2</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Buy Solana on the exchange
                    </div>
                    <div className='howto-instruction-text'>
                      Purchase Solana and finalize payment
                    </div>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>3</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Send Solana to your Solana wallet
                    </div>
                    <div className='howto-instruction-text'>
                      Copy your address from your Solana wallet and send to your address. Your wallet address can be found on Phantom here:
                    </div>
                    <img src='https://cdn.hngr.co/copy-address.png' style={{marginTop:'8px', marginBottom:'24px'}}/>
                  </div>
                </div>

              </div> {/* instrution box */} 
            </div>
          </div>

 
          <div className='howto-section'>
            <img src='https://cdn.hngr.co/grid-globe.png' className='howto-img' />
            <div className='howto-section-right'>
              <div className='howto-section-title'>
                NFTs
              </div>

              <div className='howto-section-blurb'>
                Solana can power smart contracts, decentralized finance (DeFi) apps and much more. If you made it this far, you’re most likely interested in learning about NFTs.
              </div>

              <div className='howto-green-subtitle'>
                What’s an NFT?
              </div>

              <div className='howto-section-blurb'>
                A non-fungible token (NFT) is a unique digital item where value is determined by factors such as rarity, hype, and community support. NFTs in your wallet can be represented by artwork that could be: an ape, a pizza, a breakfast sandwich, or even a trading card of your favorite restaurant.
                <br/><br/>

                Examples of real world non-fungible items include: The Mona Lisa, Babe Ruth’s 1916 Rookie Card, or even real estate. 
              </div>

              <div className='howto-green-subtitle'>
                What does it mean to “mint”?
              </div>

              <div className='howto-section-blurb'>
                NFTs come into existence on the blockchain by means of a process called minting.
                <br/><br/>
                The concept of minting in the crypto space is taken from the idea of minting physical currency. For example, when a US dollar is minted it is put into circulation.
                <br/><br/>
                Minting an NFT is the process of establishing it as an asset (token) on the blockchain. This is done when you exchange cryptocurrency such as SOL, for an NFT. By doing this, you become the original owner of it and that information is forever coded into the metadata of the token.
              </div>


              <div className='howto-green-subtitle'>
                What can I do with my NFT?
              </div>

              <div className='howto-section-blurb'>
                The sky is the limit. For example, with our Irazu X Supper Club NFT, you’ll have real world benefits. For starters, you can order food! Sign into <a href='https://irazu.hngr.co' target='_blank' className='green-font'>irazu.hngr.co</a> with your Solana wallet and recieve perks such as a free Oatmeal Shake. Also, get pre-access to our exclusive whitelist for Supper Club, an NFT series dedicated to empowering local eateries.
              </div>
            </div>
          </div>

 
          <div className='howto-section'>
            <img src='https://cdn.hngr.co/grid-phone.png' className='howto-img' />
            <div className='howto-section-right'>
              <div className='howto-section-title'>
               UTILITY 
              </div>

              <div className='howto-section-blurb'>
                A utility NFT can be where someone receives a physical piece of art that matches the NFT they purchased; it could be unique access to an event, exclusive in-person memberships, or future use in the digital world (think within a game).
              </div>

              <div className='howto-green-subtitle'>
                How do I order food with my NFT?
              </div>

              <div className='howto-section-blurb'>
                Sign into <a href='https://irazu.hngr.co' target='_blank' className='green-font'>irazu.hngr.co</a> with your Solana wallet
              </div>

              <div className='howto-grey-instruction-box'>
                <div className='howto-instruction-box-title'>
                  How to Order Food with Solana
                </div>

                <div className='howto-instruction-hr'></div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>1</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Go to a Solana Enabled Ordering Site
                    </div>
                    <div className='howto-instruction-text'>
                      Our restaurants accept Solana and can read the NFT in your wallet for perks.
                    </div>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>2</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Connect Your Wallet
                    </div>
                    <div className='howto-instruction-text'>
                      Connect your Solana wallet using the button on the top right.
                    </div>
                  </div>
                </div>

                <div className='howto-instruction'>
                  <div className='howto-instruction-number'>3</div>
                  <div className='howto-instruction-right'>
                    <div className='howto-instruction-title'>
                      Redeem Your Perk
                    </div>
                    <div className='howto-instruction-text'>
                      Claim your offer after you are connected. 
                    </div>
                  </div>
                </div>

              </div> {/* instrution box */} 


            </div>
          </div>




        
        
        </div>  {/* mint-page-content */}
      </div> 

    )
  }
}
      
export default HowTo 

