import "./LandingPage.css"
import Discord from "../svg/Discord"

const Btn = props => {
  return (
    <a href={window.DISCORD_LINK} className='generic-black-button' style={props.style}>
      <div style={{display:'flex', alignItems:'center'}}>
        <div style={{marginRight:'4px'}}>Join Our Discord</div>
        <Discord style={{width:'24px', height:'19px', marginLeft:'4px'}} />
      </div>
    </a>
  )
}


export default Btn 
