import React, { Component } from "react"
import Footer from "./Footer"
import Header from "./Header"
import * as solanaWeb3 from '@solana/web3.js';
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { encodeURL, createQR } from '@solana/pay';
import BigNumber from 'bignumber.js';

const Api = require ("./api")
const CSHELL_ADDRESS = 'EGtNVkne6Qj2UtdwL94xMACunpEfhwuRxTcAys9RUs61'

class Playground extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      loading:false
    }
  }

  componentDidMount(){
    this.getSolanaPayLink()
  }


  async getSolanaPayLink(){
    const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
    console.log('2. 🛍 Simulate a customer checkout \n');
    const recipient = new PublicKey('ArJngn2Zc1AzXeinDFsfTgYr2moM24nL1uQx559qDrSc');
    const amount = new BigNumber(20);
    const reference = new Keypair().publicKey;
    const label = 'Jungle Cats store';
    const message = 'Jungle Cats store - your order - #001234';
    const memo = 'JC#4098';
     
    /**
     * Create a payment request link
     *
     * Solana Pay uses a standard URL scheme across wallets for native SOL and SPL Token payments.
     * Several parameters are encoded within the link representing an intent to collect payment from a customer.
     */
    console.log('3. 💰 Create a payment request link \n');
    const url = encodeURL({ recipient, amount, reference, label, message, memo });
    this.setState({solanaPayLink:url})
  }

  airDrop(){
    if(!window.solana.isConnected){
      alert('CONNECT YOUR WALLET!')
      return
    }
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/fcsc/airdrop-solana',
      method:'post',
      data:{
        address:window.solana.publicKey.toString()
      },
      success:(resp)=>{
        this.setState({loading:false, txn:resp.txn})
        alert('Check your devnet wallet for 0.01 SOL!')
        this.getBalance()
      },
      failure:(err)=>{
        this.setState({loading:false})
        alert('error')
      }
    })
  }

  airDropCshells(){
    if(!window.solana.isConnected){
      alert('CONNECT YOUR WALLET!')
      return
    }
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/fcsc/airdrop-cshells',
      method:'post',
      data:{
        address:window.solana.publicKey.toString()
      },
      success:(resp)=>{
        this.setState({loading:false, txn:resp.txn})
        alert('Check your devnet wallet for 5 EGtNVkne....')
        this.getBalance()
      },
      failure:(err)=>{
        this.setState({loading:false})
        alert('error')
      }
    })
  }

  async getBalance(){
    var connection = new solanaWeb3.Connection(
      'https://api.devnet.solana.com', 'confirmed')
    let account = await connection.getAccountInfo(window.solana.publicKey)
    let lamports = account.lamports
    let sol = lamports / 1000000000
    this.setState({solBalance:sol})

    // cshells

    let accounts = await connection.getParsedProgramAccounts(
      new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
      {
        filters: [
          {
            dataSize: 165, // number of bytes
          },
          {
            memcmp: {
              offset: 32, // number of bytes
              bytes:window.solana.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string

            },
          },
        ],
      }
    );

    accounts.forEach((account, i) => {
      let mint = account.account.data["parsed"]["info"]["mint"]

      // CSHELL ADDRESS
      if(mint === CSHELL_ADDRESS){ 
        let amt = account.account.data["parsed"]["info"]["tokenAmount"]["uiAmount"]
        this.setState({cshellBalance:amt})
      }
   
    })

  }

  render(){
    return (
      <div className='page'>
        <Header/>
        <div className='content'>
          
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div className='tutorial-page-title' style={{fontSize:'120px', color:'white'}}>PLAYGROUND!</div>
            <div>

              <div
                onClick={()=>this.getBalance()}
                style={{marginBottom:'30px'}}
                className='button button-text-only'
              >
                Get Balance
              </div>

              <a
                style={{marginBottom:'30px'}}
                href={this.state.solanaPayLink}
              >
                Get Payment Link
              </a>






              <div style={{color:'white', fontSize:'40px', marginBottom:'20px'}}>Balances</div>
              <table style={{fontSize:'25px', color:'white', width:'600px'}}>
                <tbody>
                  <tr style={{marginBottom:'25px'}}>
                    <td style={{width:'150px'}}>SOL</td>
                    <td>{this.state.solBalance}</td>
                  </tr>
                  
                  <tr>
                    <td style={{width:'150px'}}>CSHELLS</td>
                    <td>{this.state.cshellBalance}</td>
                  </tr>



                </tbody>
              </table>

            </div>
          </div>

          {this.state.loading === true ? ( 
            <div className='tutorial-page-title'>Loading...</div>
          ) : (
            <>
              <div 
                style={{marginTop:'30px'}} 
                className='button button-text-only'
                onClick={()=>this.airDrop()}
              >
                Airdrop me some SOL

              </div>

              <br/><br/>
              
              <div
                onClick={()=>this.airDropCshells()}
                style={{marginTop:'30px'}}
                className='button button-text-only'
              >
                Airdrop me somerandom token (placeholder for CSHELLS )
              </div>


              <br/><br/><br/><br/><br/>
              {this.state.txn && (
                <div style={{color:'white'}}>
                  <a style={{color:'white', fontSize:'25px', marginTop:'50px'}} target='_blank' href={`https://solscan.io/tx/${this.state.txn}?cluster=devnet`}>
                    View Last Transaciton on Solscan
                  </a>
                </div>
              )}
            </>
          )}

        </div>
        <Footer/>
      </div>

    )
  } 

}
      
export default Playground 
