import React, { Component } from "react"
import Header from "./Header"
import withWallet from "../withWallet"
import classnames from "classnames"
import withRouterAndRef from "../withRouterAndRef"
import WalletButton from "./WalletButton"
import "./Minting.css"
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { Connection as MPConnection, programs } from '@metaplex/js'
import localforage from "localforage"
const { metadata: { Metadata } } = programs;
const Api = require ("../api")
const detectWallet = require ("./detectWallet")

class MintPage extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      FAT:window.subdomain.indexOf('fat') > -1
    }
    window.m = this
  }

  componentDidMount(){
    this.mounted = true
    this.loadMintInfo() 
    this.checkForHamburglar()
  }

  componentWillUnmount(){
    this.mounted = false
  }

  componentDidUpdate(prevProps, prevState){
    if( 
      this.props.publicKey  &&  
      this.state.publicKey &&  
      this.props.publicKey.toString() !== this.state.publicKey.toString()
    ){  
      this.setState({publicKey:this.props.publicKey}, this.checkForOwner(true))
    }   

    if( 
      this.props.publicKey &&
      !this.state.publicKey 
    ){ 
      this.setState({publicKey:this.props.publicKey, loading:true},()=>{
        this.checkForOwner(true)
        setTimeout(()=>{
          if(this.state.name) this.setState({loading:false}) 
        }, 3000)
      })
    }   

    if(this.state.publicKey && !this.props.publicKey){
      this.setState({publicKey:null})
    }   

  }

  checkForHamburglar(){
    let that = this
    localforage
      .getItem('hamburglar')
      .then(function (value) {
        if(value){
          that.setState({hamburglar:value})
        }
      })
      .catch(function (err) {
        console.error("check for hamburgler error", err)
      })
  }


  loadMintInfo(){ 
    Api.callApi({
      endpoint:'/api/mint-info/'+window.subdomain,
      method:'get',
      success:(resp)=>{
        if(resp.error){
          this.setState({error:resp.error})
        } else {
          this.setState({
            name:resp.name,
            mint_date_str:resp.date_str,
            premint_date_str:resp.premint_date_str,
            premint_date_str_at:resp.premint_date_str_at,
            ms:resp.ms,
            mint_open:resp.mint_open,
            premintOpen:resp.premint_open,
            minted:resp.minted,
            limit:resp.limit,
            soldOut:resp.mint_full,
          },()=>setTimeout(()=>this.setState({loading:false}), 3000))
        }
      },
      failure:(err)=>{
        alert('error')
      }
    })
  }

  checkForMinting(counter){
    if(!this.mounted) return

    if(counter >= 60){
      this.setState({cooking:null, error:'Mint Failed! Please try again!'}, ()=>this.checkForOwner())
      this.postToSlack("[mint timed out on frontend]"  + this.props.publicKey.toString()) 
      return
    }
    Api.callApi({
      endpoint:"/fcsc/check-if-nft-transferred",
      method:'post',
      data:{
        address:this.props.publicKey.toString(),
        nft_id:this.state.nft_id
      },
      success:(resp)=>{
        if(resp.ready){
          this.postToSlack("[mint looks succesful] "  + this.props.publicKey.toString()) 
          this.checkForOwner()
        } else{
          setTimeout(()=>this.checkForMinting(counter + 1), 3000)
        }
      },
      failure:(err)=>{
        setTimeout(()=>this.checkForMinting(counter + 1), 3000)
      }
    })
  }


 async checkForOwner(ignoreRetry){
    console.log("check for owner....")
    if(!this.mounted) return
    if(!this.state.publicKey){
      console.log("checking for owner but no public key!")
      setTimeout(()=>this.checkForOwner, 3000)
      return
    } 
    let connection
    let accounts
    let success = false
    try {
      connection = new solanaWeb3.Connection(
      'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/', 'confirmed')
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    }
    catch (err){
      connection = new solanaWeb3.Connection(
      'https://nd-135-906-691.p2pify.com/58f40b25ff76a90476de9023bd163e25', 'confirmed')

      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes:this.state.publicKey.toString() //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );
      success = true
    } finally {
      if(!success){
        setTimeout(()=>this.checkForOwner(), 3000)
        return
      }
    }

    let mints = []
    for(let i in accounts){
      let amt = accounts[i].account.data['parsed']['info']['tokenAmount']['uiAmount']
      if(amt === 1){
        mints.push(accounts[i].account.data['parsed']['info']['mint'])
      }
    }

    Api.callApi({
      endpoint:"/fcsc/check-if-own-nft",
      method:'post',
      data:{
        mints:mints,
        mint_url:window.subdomain
      },
      success:(resp)=>{
        if(resp.owner){
          this.postToSlack("[mint page success!] "  + this.props.publicKey.toString()) 
          this.props.history.push('/owner')
        }
      },
      failure:(err)=>{
        alert('Error verifying NFT ownership')
      }
    })

    if(!ignoreRetry){
      setTimeout(()=>this.checkForOwner(), 3000)
    }
  }


  postToSlack(msg){
    Api.callApi({
      endpoint:"/fcsc/post-to-slack",
      method:'post',
      data:{msg:msg},
      success:(resp)=>console.log("sent msg"),
      failure:(err)=>{
        console.log("error posting to slack", err)
      }
    })
 
  }

  async mint(){
    if(this.state.hamburglar){
      this.setState({error:'Are you trying to mint multiple times? That’s a no-no'})
      this.postToSlack(`Hamburglar alert!!! Naughty wallet: ${this.state.publicKey.toString()}. Original wallet: ${this.state.hamburglar}`)
      this.checkForOwner()
      return
    }


    let msg = new TextEncoder().encode("I want my NFT")
    let signature = await this.props.signMessage(msg)
    window.sig = signature
    let signatureBase64 = Buffer.from(signature).toString('base64')
    this.setState({cooking:true, error:null})

    this.postToSlack("[clicked mint] " + this.props.publicKey.toString()) 

    let payload = {
      signature:signature,
      address:this.props.publicKey.toString(),
      mint_url:window.subdomain
    }

    let params = new URLSearchParams(window.location.search)
    if(params.get('utm_source')){
      payload['utm_source'] = params.get('utm_source')
    }

    Api.callApi({
      endpoint:"/fcsc/claim-nft",
      method:'post',
      data:payload,
      success:(resp)=>{
        if(!resp.success){
          if(resp.error === 'already_have'){
            this.checkForOwner()
          } else if(resp.error === 'sold_out'){
            this.setState({soldOut:true})
            this.postToSlack("[tried to mint error] " + resp.error + ' ' + this.props.publicKey.toString()) 
           } else{
            this.postToSlack("[tried to mint error] " + resp.error + ' ' + this.props.publicKey.toString()) 
            alert(resp.error)
          }
        } else {
          this.setState({nft_id:resp.nft_id, state:'minting'}, 
            ()=>this.checkForMinting(0))
          if(resp.already_in_progress){
            this.postToSlack("[mint screen again but minting already in progress] " + this.props.publicKey.toString()) 
          } 
        }
      },
      failure:(err)=>{
        alert('error')
        this.setState({page:'mint'})
      }
    })
  }





  render() {
   if(this.state.loading){
      return (
        <div className='loading-page'>
          <img src='https://cdn.hngr.co/burger-loader.gif' />
        </div>
      )
    }

    if(this.state.cooking){
      return (
        <div className='mint-page '>
          <Header link='/'/>
          <div className='cooking-nft-content'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
              <img 
                src='https://cdn.hngr.co/premintspinner.gif'
                style={{height:'96px', width:'96px', marginBottom:'24px'}}
              />
              <img src='https://cdn.hngr.co/Cooking-up-your-NFT.gif' 
                style={{height:'74px', marginBottom:'24px'}}
              />
              <div className='cooking-green-text'>
                This may take up to 2-3 minutes. Do not leave this screen.
              </div>
            </div>
          </div>
        </div>
      )
    }

    if(!this.state.mint_open){
      return (
       <div className='mint-page '>
        <Header link='/'/>

        <div className='premint-outer-container'  style={{position:'relative'}}>

          <div className='premint-ordering-content' >
            <div className='premint-ordering-left'>
              <div className='premint-right-tags'>
                <div className='premint-tag premint-tag-green' style={{marginRight:'20px'}}>
                  <div>{this.state.minted}/{this.state.limit} Minted</div>
                </div>

                <div className='premint-tag premint-tag-grey'>
                  <div>FREE TO CLAIM</div>
                </div>
              </div>

              <div className='premint-ordering-title'>
                NFT DROP IS ON <span className='green-font'>5/28</span> AT <span className='green-font'>3PM</span> PDT
              </div>

              <div className='mark-your-calendar'>
                Mark your calendar. You won’t want to miss this.
              </div>

              <div style={{display:'flex'}}>
                <a className='mint-section-green-button' target='_blank' href={window.DISCORD_LINK}>
                  <div>Join Our Discord</div>
                  <DiscordBlack style={{marginLeft:'8px', fill:'black'}}/>
                </a>

                <a 
                  href='/'
                  className='mint-page-black-button' 
                  style={{marginLeft:'24px'}}
                >
                  <div>Back to Home</div>
                </a>


              </div>

            </div>

            
            <div className='premint-ordering-img'>
              <img 
                src='https://cdn.hngr.co/Silhouette-Card.gif'
                className='premint-ordering-img'
              />
            </div>

         </div> {/* premint-ordering-content */}
        </div> {/* mint-page-content */ }
      </div>

    )


   } else if(!this.state.publicKey){
      return (
        <div className='mint-page '>
          <Header link='/'/>

          <div className='premint-outer-container'  style={{position:'relative'}}>
            <div className='premint-ordering-content' style={{flexDirection:'column'}}>
              <div className='mint-centered-tag-row'>
                <div className='premint-tag premint-tag-green' style={{marginRight:'20px'}}>
                  <div>{this.state.minted}/{this.state.limit} Minted</div>
                </div>

                <div className='premint-tag premint-tag-grey'>
                  <div>FREE TO CLAIM</div>
                </div>
              </div>

              <div className='premint-ordering-title'>
                CONNECT YOUR WALLET
              </div>

              <div className='mint-now-live'>
                NFT Minting is now live.
              </div>

              <div className='connect-to-continue'>
                Connect your Solana wallet below to continue.
              </div>

              <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'24px'}}>
                <WalletButton class2='green-connect-button' mint={true} walletDetected={detectWallet.detectWallet()}/>

                <a 
                  className='mint-page-black-button' 
                  href='/?anchor=howto'
                  style={{marginLeft:'24px'}}
                >
                  <div>Learn How To Mint</div>
                </a>
              </div>

            
             </div> {/* premint-ordering-content */}
           </div> {/* mint-page-content */ }
        </div>

      )
    }

    // mint open with wallet
    return (
      <div className='mint-page '>
        <Header link='/'/>

        <div className='premint-outer-container'  style={{position:'relative'}}>
          {this.state.error && (
            <div className='mint-error'>
              <Alert />
              <div>{this.state.error}</div>
            </div>
          )}

          <div className='premint-ordering-content' >
            <div className='premint-ordering-left'>
              <div className='premint-right-tags'>
                <div className='premint-tag premint-tag-green' style={{marginRight:'20px'}}>
                  <div>{this.state.minted}/{this.state.limit} Minted</div>
                </div>

                <div className='premint-tag premint-tag-grey'>
                  <div>FREE TO CLAIM</div>
                </div>
              </div>

              {this.state.soldOut ? (
                <div className='premint-ordering-title'>
                  NFT IS SOLD OUT
                </div>
              ) : (
                <div className='premint-ordering-title'>
                  MINT MY NFT
                </div>
              )}
              
              {this.state.soldOut ? (
                <>
                  <div className='premint-green-subtitle'>
                    Gotta be quicker next time.
                  </div>

                  <div className='mint-page-landing-blrb'>
                    These went quick. Join us at The Supper Club to get updates on the next drop.
                  </div>
                </>
              ) : (
                <>
                  <div className='mint-page-landing-blrb'>
                      Now’s your chance to mint the tastiest NFT in Web3. <br/>
                      Get them while they’re hot.
                  </div>
                </>
              )}

              {this.state.soldOut ? (
                <div style={{display:'flex'}}>
                  <div className='mint-page-green-button' onClick={()=>this.props.history.replace('/')}>
                    <div>Back To Home</div>
                  </div>
                </div>

              ) : (
                <div style={{display:'flex'}}>
                  {this.state.publicKey ? (
                    <div className='generic-green-button' onClick={()=>this.mint()}>
                        <div>Mint 1 NFT</div>
                    </div>
                  ) : (
                    <div className='generic-green-button-wallet-container'>
                      <WalletButton class2='green-connect-button' mint={true} walletDetected={detectWallet.detectWallet()}/>
                    </div>
                  )}
                </div>
              )}

            </div>

            
            <div className='premint-ordering-img'>
              {this.state.FAT ? (
                <img 
                  src='https://cdn.hngr.co/Silhouette-Card.gif'
                  className='premint-ordering-img'
                />
 
              ) : (
                <img 
                  src='https://cdn.hngr.co/Silhouette-Card-v2.gif'
                  className='premint-ordering-img'
                />
              )}
            </div>
 


         </div> {/* premint-ordering-content */}
        </div> {/* mint-page-content */ }
      </div>

    )
  }
}
      
export default withRouterAndRef(withWallet(MintPage)) 

const Alert = props => (<svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9994 28.3333C16.7506 28.3356 18.4851 27.9917 20.1031 27.3215C21.7211 26.6513 23.1906 25.6679 24.4274 24.428C25.6673 23.1913 26.6507 21.7217 27.3209 20.1037C27.9911 18.4857 28.335 16.7513 28.3327 15C28.3349 13.2487 27.9911 11.5142 27.3209 9.89626C26.6506 8.27829 25.6673 6.80871 24.4274 5.57197C23.1906 4.33199 21.7211 3.34864 20.1031 2.67843C18.4851 2.00822 16.7506 1.66437 14.9994 1.66664C13.2481 1.6644 11.5136 2.00827 9.89564 2.67848C8.27768 3.34869 6.8081 4.33202 5.57136 5.57197C4.33141 6.80871 3.34807 8.27829 2.67787 9.89626C2.00766 11.5142 1.66379 13.2487 1.66603 15C1.66376 16.7513 2.00761 18.4857 2.67782 20.1037C3.34803 21.7217 4.33138 23.1913 5.57136 24.428C6.8081 25.6679 8.27768 26.6513 9.89564 27.3215C11.5136 27.9917 13.2481 28.3355 14.9994 28.3333V28.3333Z" stroke="#FF0000" stroke-width="2.66667" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9987 23.6666C15.4407 23.6666 15.8646 23.4911 16.1772 23.1785C16.4898 22.8659 16.6654 22.442 16.6654 22C16.6654 21.558 16.4898 21.134 16.1772 20.8215C15.8646 20.5089 15.4407 20.3333 14.9987 20.3333C14.5567 20.3333 14.1327 20.5089 13.8202 20.8215C13.5076 21.134 13.332 21.558 13.332 22C13.332 22.442 13.5076 22.8659 13.8202 23.1785C14.1327 23.4911 14.5567 23.6666 14.9987 23.6666Z" fill="#FF0000"/>
<path d="M15 6.99994V17.6666" stroke="#FF0000" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)


const DiscordBlack = props => (<svg {...props} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2477 2.06348C14.0673 1.50098 12.8184 1.1013 11.5342 0.875C11.3743 1.16952 11.1874 1.56566 11.0585 1.88081C9.67389 1.66878 8.30202 1.66878 6.94288 1.88081C6.81406 1.56573 6.62295 1.16952 6.46152 0.875C5.17606 1.1014 3.92616 1.5021 2.74514 2.06641C0.395012 5.68326 -0.242091 9.21018 0.076425 12.6872C1.63519 13.8726 3.14578 14.5928 4.63093 15.0641C5.00002 14.5471 5.32629 13.9999 5.60637 13.4279C5.07314 13.2211 4.55909 12.9664 4.07032 12.6666C4.19895 12.5695 4.32454 12.4683 4.44692 12.3631C7.40863 13.774 10.6267 13.774 13.5531 12.3631C13.676 12.4676 13.8016 12.5688 13.9296 12.6666C13.4401 12.9671 12.9251 13.2224 12.3908 13.4294C12.6724 14.0037 12.9981 14.5515 13.3662 15.0655C14.8528 14.5943 16.3648 13.8741 17.9235 12.6872C18.2973 8.6565 17.2851 5.16189 15.2477 2.06341V2.06348ZM6.0099 10.5489C5.12079 10.5489 4.39165 9.70356 4.39165 8.67419C4.39165 7.64482 5.10526 6.79806 6.0099 6.79806C6.91461 6.79806 7.64368 7.64332 7.62814 8.67419C7.62955 9.70356 6.91461 10.5489 6.0099 10.5489ZM11.9901 10.5489C11.101 10.5489 10.3719 9.70356 10.3719 8.67419C10.3719 7.64482 11.0854 6.79806 11.9901 6.79806C12.8948 6.79806 13.6238 7.64332 13.6083 8.67419C13.6083 9.70356 12.8948 10.5489 11.9901 10.5489Z" fill="black"/>
</svg>)
