import React from 'react';
import { TutorialContext } from './TutorialContext';

export default function TutorialContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <TutorialContext.Consumer>
      {context => <Component ref={ref} tutorial={context} {...props} />}
    </TutorialContext.Consumer>
  ))
}
