import React, { Component } from "react"
import Waves from "./svg/Waves"
import withRouterAndRef from "./withRouterAndRef"
import WalletButton from "./WalletButton"
import "./Header.css"

class Header extends Component {

  render(){
    return (
        <div className='header'>
          <img 
            alt='Friends of Captain'
            className='friends-logo' 
            src='https://cdn.hngr.co/fcsc/friendsofcaptain.png'
          />
          {this.props.showApply === true ? ( 
            <div
              className='button'
              onClick={()=>this.props.history.push('apply')}
            >
              <div>Apply Now</div>
              <Waves/>
            </div> 
          ) : (
          <WalletButton/>
          )}
        </div> 
    )
  }
}
      
export default withRouterAndRef(Header)



