import React, { Component } from "react"
import TutorialContextHOC from "./contexts/TutorialContextHOC"
import Tutorial from "./Tutorial"

class Contracts extends Component {

  render(){
    return (
      <Tutorial
        tip='Smart Contracts are enforceable contracts'
        page='CONTRACTS'
        title='Smart Contracts'
        questions={{
          'What makes smart contracts "smart"?':'Their big brains',
        }}
        right={
          <>
            <div className='tutorial-right-title'>Smart Contracts</div>
            <img 
              className='tutorial-right-img'
              src='https://www.elegantthemes.com/blog/wp-content/uploads/2018/11/smart-goals.png'
            />
            <div className='tutorial-right-text'>Smart contracts are smart</div>
            <div 
              style={{marginTop:'30px'}} 
              className='button button-text-only'
              onClick={()=>this.props.tutorial.next('contracts')}
            >
              Continue
            </div>

          </>
        }
      />
    )
  }
}
      
export default TutorialContextHOC(Contracts) 
