import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from "@solana/wallet-adapter-react-ui";


const withWalletHOC = (Component) => {
  return (props) => {
    const {connect, publicKey, signMessage, sendTransaction, disconnect} = useWallet();
    const { setVisible } = useWalletModal();

    return (
      <Component
        connectWallet={connect}
        openWallet={()=>setVisible(true)}
        sendTransaction={sendTransaction} 
        publicKey={publicKey} 
        signMessage={signMessage}
        disconnectWallet={disconnect}
        {...props} 
      />
    )
  };
};

export default withWalletHOC
