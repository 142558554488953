import React, { Component, useMemo, useEffect, useState } from "react"
import { isChrome , isMobile } from 'react-device-detect';
import { WalletModalProvider, WalletMultiButton, WalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import X from "../svg/X"

require('@solana/wallet-adapter-react-ui/styles.css');

const detectWallet = require ("./detectWallet")



const WalletButton = (props) => {

  const { wallet, connect, connecting, connected, publicKey } = useWallet();
  const [modalOpen, setModalOpen] = useState(0);


  window.wallet = wallet

  const content = useMemo(() => {
      if (connecting) return 'Connecting ...';
      if (connected) return 'Connected';
      if (wallet) {
        if(props.connectOneWord) return 'Connect'
        else return 'Connect Wallet';
      }
      if(props.connectOneWord) return 'Connect'
      else return 'Connect Wallet';
  }, [connecting, connected, wallet]);


  const handleClickOutside = (e) =>{
    if(!e) return
    if(!e.target) return
    if(!e.target.className) return

  /*
    if(!this.state.loginModal && !this.state.phantomModal) {
      return
    }   
  */
    if (e.target.className.indexOf('modal-background') > -1){
      setModalOpen(false)
    }   
  }


   window.addEventListener('mousedown', handleClickOutside)


  useEffect(() => {
    if(wallet && window.CLICKED_WALLET_PREMINT_ELIGIBILITY && props.premintEligibility){ 
      window.CLICKED_WALLET_PREMINT_ELIGIBILITY = false 
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    } 

    if(wallet && window.CLICKED_MINT && props.mint){ 
      window.CLICKED_MINT = false 
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    } 

    if(wallet && window.CLICKED_WALLET_HEADER && props.header){ 
      window.CLICKED_WALLET_HEADER = false 
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    } 

    return () => {
       window.removeEventListener('mousedown', handleClickOutside)
    }


  }, [wallet]);
  
  if(!isChrome) return null

  if(props.walletDetected){
    if (connected && wallet && wallet.adapter.name === 'Phantom' && publicKey){
      let str = publicKey.toString()
      let address = str.substr(0,4) + '...' + str.substr(str.length-4, str.length)
      return (
        <WalletMultiButton className='hide-i'>
          <div className='phantom-connected-button'>
            <Phantom/>
            <div>{address}</div>
          </div>
        </WalletMultiButton>
      )
    } else {
      return (
        <WalletMultiButton className='hide-i'>
          <div 
            id={props.id2}
            onClick={()=>{
              if(props.premintEligibility){
                window.CLICKED_WALLET_PREMINT_ELIGIBILITY = true
              } 

              if(props.mint){
                window.CLICKED_MINT = true
              }

              if(props.header){
                window.CLICKED_WALLET_HEADER = true 
              }
            }}

            className={props.class2 ? props.class2 : 'mint-wallet-header-connect-button'}
          >
            <div>{content}</div>
          </div>
        </WalletMultiButton>
      )
    }
  } else {
    return (
      <>
        {modalOpen && (<PhantomModal close={()=>setModalOpen(false)}/>)}
        <div
          onClick={()=>{
            setModalOpen(true)
          }}
          className={props.class2 ? props.class2 : 'mint-wallet-header-connect-button'}
        >
          <div>Connect Wallet</div>
        </div>
      </>

    )
  }
}

export default WalletButton

const Phantom = props => (
  <svg {...props} width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.4661 11.2045H21.6414C21.6414 5.42522 16.9771 0.742287 11.2164 0.742287C5.51137 0.742287 0.865606 5.29513 0.754108 11.0001C0.64261 16.8352 6.14319 21.9084 11.9968 21.9084H12.7402C17.9063 21.9084 24.8377 17.8759 25.9155 12.9699C26.0828 12.1709 25.5625 11.409 24.7634 11.2417C24.6705 11.2045 24.5776 11.2045 24.4661 11.2045ZM6.99801 11.4647C6.99801 12.2452 6.36619 12.8584 5.5857 12.8584C4.80521 12.8584 4.19197 12.2266 4.19197 11.4647V9.19757C4.19197 8.41708 4.8238 7.80384 5.60428 7.80384C6.38477 7.80384 6.99801 8.43566 6.99801 9.19757V11.4647ZM11.8668 11.4647C11.8668 12.2452 11.2349 12.8584 10.4545 12.8584C9.67397 12.8584 9.06073 12.2266 9.06073 11.4647V9.19757C9.06073 8.41708 9.69255 7.80384 10.473 7.80384C11.2535 7.80384 11.8668 8.43566 11.8668 9.19757V11.4647Z" fill="url(#paint0_linear_651_10046)"/>
  <defs>
  <linearGradient id="paint0_linear_651_10046" x1="13.3485" y1="0.790603" x2="13.3485" y2="21.9065" gradientUnits="userSpaceOnUse">
  <stop stop-color="white"/>
  <stop offset="1" stop-color="white" stop-opacity="0.82"/>
  </linearGradient>
  </defs>
  </svg>
)


const PhantomModal = props => (
  <div className='modal-background hngr-login-modal-background'>
    <div className='modal-content phantom-modal-content'>
      <X
        className='modal-x-close'
        onClick={()=>props.close()}
      />

      <img
        src='https://cdn.hngr.co/phantomincon.png'
        style={{width:'80px', height:'80px'}}
      />

      <div style={{width:'100%'}}>
        <div className='phantom-modal-title'>
          Download the Phantom Extension
        </div>
      </div>

      <div style={{width:'100%'}}>
        <div className='phantom-modal-blurb'>
          Phantom makes it safe & easy for you to store, buy, send receive, swap tokens and collect NFTs on the Solana blockchain.
        </div>
      </div>

      <a
        className='phantom-modal-button'
        href='https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa'
      >
        <div>Download Phantom</div>
      </a>

      <div className='phantom-modal-bottom-section'>
        <div className='phantom-modal-bottom-section-text'>
          Already installed  Phantom?
        </div>
        <a
          href={window.location.href}
          target='_blank'
          className='phantom-modal-bottom-section-link'
        >
          <div style={{display:'flex', alignItems:'center'}}>
            <div>Try opening in a new tab</div>
            <TabArrow style={{marginLeft:'4px'}}/>
          </div>
        </a>
      </div>

    </div>
  </div>
)


const TabArrow = props => (
  <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.5 1V13H15.5V1H3.5ZM14.5 12H4.5V2H14.5V12Z" fill="white"/>
  <path d="M2.5 14V3.5L1.5 2.5V15H14L13 14H2.5Z" fill="white"/>
  <path d="M6 4L8.5 6.5L5.5 9.5L7 11L10 8L12.5 10.5V4H6Z" fill="white"/>
  </svg>
)
