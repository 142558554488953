import React, { Component } from "react"
import Countdown from 'react-countdown';
import Header from "./Header"
import withRouterAndRef from "../withRouterAndRef"
import { isChrome , isMobile } from 'react-device-detect';
import "./LandingPage.css"

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if(days < 10) days = '0' + days
  if(hours < 10) hours = '0' +  hours
  if(minutes < 10) minutes = '0' + minutes
  if(seconds < 10) seconds = '0' + seconds
  if (completed) {
    // Render a completed state
    return (<div> COMPLETE </div>) 
  } else {
    return(
      <div className='countdown-clock'>
        {days}:{hours}:{minutes}:{seconds}
      </div>
    )
  }
}


const Api = require ("../api")

class UnsupportedBrowser extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      isChrome:isChrome,
      isMobile:isMobile,
      FAT:window.subdomain.indexOf('fat') > -1
    }
  }

  componentDidMount(){
    this.loadMintInfo()
  }

  loadMintInfo(){
    Api.callApi({
      endpoint:'/api/mint-info/'+window.subdomain,
      method:'get',
      success:(resp)=>{
        if(resp.error){
          this.setState({error:resp.error})
        } else {
          this.setState({
            name:resp.name,
            mint_date_str:resp.date_str,
            premint_date_str:resp.premint_date_str,
            ms:resp.ms,
            ms_premint:resp.ms_premint,
            mint_open:resp.mint_open,
            premint_open:resp.premint_open,
            minted:resp.minted,
            limit:resp.limit,
            loading:false,
            mint_full:resp.mint_full
          })
        }
      },
      failure:(err)=>{
        alert('error')
      }
    })
  }


  
  renderNotChrome(){
    return (
        <div className='mint-page grid-background'> 
          <Header link='/' hideWallet={true} hideAbout={true}/>
          <div className='mint-page-above-the-fold'>
            <div className='mint-page-content mint-page-top-content-outer'>
              <div className='mint-page-top-content' style={{flexDirection:'column'}}>
                <div style={{height:'100%'}}>
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'center', height:'100%'}}>
                    <div className='mint-page-above-fold-title-huge'>
                      SORRY, YOUR BROWSER ISN’T SUPPORTED <span className='green-font'>:(</span> 
                    </div>

                    <div className='mint-page-above-fold-blurb' style={{margin:'24px 0px'}}>
                      Sorry friend. this only works if you use Google Chrome and a Solana wallet.
                      <br/><br/>
                      You can continue to explore, but you won’t be able to mint a NFT.
                    </div>

                    <div className='mint-page-buttons-container'>

                      <a 
                        target='_blank'
                        href={window.DISCORD_LINK}
                        className='mint-page-green-button'
                      >
                        <div>Join Our Discord</div>
                        <DiscordBlack style={{marginLeft:'8px'}} />
                      </a>

                    </div> {/* mint-page-buttons-container */ }
                  </div>
                </div>
              </div>

            </div> {/*mint-page-content*/}

            <div className='mint-green-bar'>
              <div className='mint-page-content '>
                <div className='mint-green-bar-content' style={ {justifyContent:'center'} }>

                 
                  {this.state.mint_open ? (
                    <>
                      {this.state.mint_full ? (  
                        <div className='green-bar-sold-out'>
                          <div>NFT IS SOLD OUT</div>
                        </div>

                      ) : (
                        <div className='green-bar-open-tag'>
                          <div>MINT OPEN</div>
                        </div>
                      )}
                    </>
                  ) : (
                  <div className='green-bar-countdown-section'>
                    <div className='green-bar-countdown-section-left'>
                      <div className='green-bar-countdown-section-left-top'>
                        Public Mint
                      </div>
                      <div className='green-bar-countdown-section-left-bottom'>
                        {this.state.mint_date_str}
                      </div>
                    </div>
                    <Countdown date={Date.now() + this.state.ms} renderer={renderer}/>
                  </div>
                )}
                
                
                
                </div> {/* mint-green-bar-content */}
              </div> {/* mint-page-content inside green bar */}



            </div> {/* mint-green-bar */}
          </div> {/* mint-page-above-the-fold*/}
        </div>
    )
  }

  renderMobile(){
    return (
      <div className='mobile-container'>
        <div className='mobile-header'>
          {this.state.FAT ? (
            <img 
              onClick={()=>window.location.href='/'}
              style={{height:'30px', cursor:'pointer'}} 
              src='https://cdn.hngr.co/supperclub-fat-logo.gif' 
            />
          ) : (
            <img 
              style={{height:'30px', cursor:'pointer'}} 
              src='https://cdn.hngr.co/Irazu-Supper-Club-Logo.gif' 
            />
          )}
        </div>

        <div className='mobile-content'>
          <div className='mobile-title'>
            SORRY, YOUR MOBILE BROWSER ISN’T SUPPORTED <span className='green-font' style={{whiteSpace:'nowrap'}}>:(</span>
          </div>

          <div className='mobile-blurb'>
            This website is best enjoyed on desktop, preferably a Solana wallet capable browser such as Google Chrome.
          </div>

          <a 
            className='mobile-black-button'
            target='_blank'
            href={window.DISCORD_LINK}
          >
            <div>Join Our Discord</div>
          </a>
          
        </div>
      </div>
    )
  }

  render(){
   if(this.state.loading){
    return (
      <div className='loading-page'>
        <img src='https://cdn.hngr.co/burger-loader.gif' />
      </div>
    )
  }

    if(this.state.isMobile) {
      return this.renderMobile()
    } else { 
      return this.renderNotChrome()
    }
  }
}
      
export default UnsupportedBrowser 


const DiscordBlack = props => (<svg {...props} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2477 2.06348C14.0673 1.50098 12.8184 1.1013 11.5342 0.875C11.3743 1.16952 11.1874 1.56566 11.0585 1.88081C9.67389 1.66878 8.30202 1.66878 6.94288 1.88081C6.81406 1.56573 6.62295 1.16952 6.46152 0.875C5.17606 1.1014 3.92616 1.5021 2.74514 2.06641C0.395012 5.68326 -0.242091 9.21018 0.076425 12.6872C1.63519 13.8726 3.14578 14.5928 4.63093 15.0641C5.00002 14.5471 5.32629 13.9999 5.60637 13.4279C5.07314 13.2211 4.55909 12.9664 4.07032 12.6666C4.19895 12.5695 4.32454 12.4683 4.44692 12.3631C7.40863 13.774 10.6267 13.774 13.5531 12.3631C13.676 12.4676 13.8016 12.5688 13.9296 12.6666C13.4401 12.9671 12.9251 13.2224 12.3908 13.4294C12.6724 14.0037 12.9981 14.5515 13.3662 15.0655C14.8528 14.5943 16.3648 13.8741 17.9235 12.6872C18.2973 8.6565 17.2851 5.16189 15.2477 2.06341V2.06348ZM6.0099 10.5489C5.12079 10.5489 4.39165 9.70356 4.39165 8.67419C4.39165 7.64482 5.10526 6.79806 6.0099 6.79806C6.91461 6.79806 7.64368 7.64332 7.62814 8.67419C7.62955 9.70356 6.91461 10.5489 6.0099 10.5489ZM11.9901 10.5489C11.101 10.5489 10.3719 9.70356 10.3719 8.67419C10.3719 7.64482 11.0854 6.79806 11.9901 6.79806C12.8948 6.79806 13.6238 7.64332 13.6083 8.67419C13.6083 9.70356 12.8948 10.5489 11.9901 10.5489Z" fill="black"/>
</svg>)

