import React, { Component } from "react"
import X from "./svg/X"
import withRouterAndRef from "./withRouterAndRef" 
import "./Modal.css"

class Apply extends Component {


  componentDidMount(){
    document.body.style.overflowY = "hidden";
  }

  componentWillUnmount(){
    document.body.style.overflowY = "auto";
  }

  close(){
    this.props.history.goBack()
  }

  render(){
    return (
      <div className='modal-background'>
        <div className='modal-content'>
          <X 
            onClick={()=>this.close()}
            className='modal-x-close'
          />
          <div className='modal-title'>VERIFY IT'S YOU</div>
          <div className='modal-text'>Enter the code we sent to</div>
          <div className='apply-modal-phone'>
            1 (555) 555-5555
          </div>
          <input 
            className='apply-modal-code-input'
            placeholder='****'
          />
          <div 
            className='modal-submit-button'
            onClick={()=>this.props.history.push("/fcsc")}
          >
            Submit
          </div>
          <div className='apply-modal-resend'>
            I didn't receieve a code
          </div>
        </div>
      </div>
    )
  }
}
      

export default withRouterAndRef(Apply) 



