import React, { Component } from "react"
import Header from "./Header"
import Footer from "./Footer"
import ProgressBar from "./ProgressBar"
import ChevronDown from "./svg/ChevronDown"
import ChevronUp from "./svg/ChevronUp"
import Lightbulb from "./svg/Lightbulb"
import "./Tutorial.css"

class Tutorial extends Component {

  constructor(props){
    super(props)
    this.state = {
      openQuestion:null
    }
  }

  render(){
    return (
      <div className='page'>
        <Header/>
        <div className='content'> 
          <ProgressBar page={this.props.page}/>
          <div className='space-between-top'>
            <div className='tutorial-left'>
              {this.props.tip && (
                <div className='tutorial-tip-container'>
                  <Lightbulb style={{marginRight:'30px'}} />
                  <div>{this.props.tip}</div>
                </div>
              )}
              <div className='tutorial-page-title'>
                {this.props.title}
              </div>
              {this.props.questions && (
                <div className='tutorial-questions-container'> 
                  {Object.keys(this.props.questions).map((question) => {
                    if(this.state.openQuestion !== question){
                      return (
                        <div 
                          onClick={()=>this.setState({openQuestion:question})}
                          key={'question' + question} 
                          className='tutorial-question-container'
                        >
                          <div className='tutorial-question'>{question}</div>
                          <ChevronDown/>
                        </div>
                      )
                    } else {
                      let answer = this.props.questions[question]
                      return (
                        <div 
                          key={'anwer' + question} 
                          className='tutorial-question-answer-outer-container'
                          onClick={()=>this.setState({openQuestion:null})}
                        >
                          <div className='tutorial-question-answer-container'>
                            <div className='tutorial-question-answer'>{question}</div>
                            <ChevronUp/>
                          </div>
                          <div className='tutorial-answer'>{answer}</div>
                        </div>
                      )

                    }
                  })}
                </div>
              )}
            </div>
            <div className='tutorial-right'>
              <div className='tutorial-right-content'>
                {this.props.right}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
      
export default Tutorial 
