import React, { Component } from "react"
import withRouterAndRef from "./withRouterAndRef"
import Footer from "./Footer"
import Header from "./Header"
import "./Apply.css"

class Apply extends Component {

  render(){
    return (
      <div className='page'>
        <Header/>
        <div className='apply-content'>
           <div className='slide-in slider' style={{textAlign:'center', marginBottom:'30px'}}>
            <div className='apply-header'>
              SOCIAL CLUB APPLICATION
            </div>
      
            <div className='medium-text' style={{marginBottom:'30px'}}>Average completion time: 3 min</div>
          </div>
            
          <div className='apply-input-section'>
            <div className='apply-input-label'>Full name *</div>
            <input
              className='apply-input'
            />
          </div>

          <div className='apply-input-section'>
            <div className='apply-input-label'>Phone number *</div>
            <input
              className='apply-input'
            />
          </div>

          <div className='apply-input-section'>
            <div className='apply-input-label'>Twitter Handle *</div>
            <input
              className='apply-input'
            />
          </div>

          <div className='apply-input-section'>
            <div className='apply-input-label'>What do you do? *</div>
            <input
              className='apply-input'
            />
          </div>

          <div className='apply-input-section'>
            <div className='apply-input-label'>In a few words, why do you want to join?</div>
            <textarea
              className='apply-textarea apply-input'
            />
          </div>

          <div 
            className='button button-text-only'
            onClick={()=>this.props.history.push("/apply/modal")}
          >
            Submit
          </div>

        </div>
        <Footer/>
      </div>

    )
  }
}
      

export default withRouterAndRef(Apply) 

