import React, { Component } from "react"
import TutorialContextHOC from "./contexts/TutorialContextHOC"
import classnames from "classnames"
import Checkmark from "./svg/Checkmark"
import "./ProgressBar.css"

class ProgressBar extends Component {
  constructor(props){
    super(props)
    this.state = {
      page:this.props.tutorial.PAGES.indexOf(this.props.page) 
    }
  }

  render(){
    return (
      <div className='progress-bar-container'> 
        {this.props.tutorial.PAGES.map((page, index) => (
          <>
            {index > 0  && (
              <div className={classnames('progress-bar-line', {'progress-bar-line-grey':index>this.state.page})}></div>
            )}
            <div>
              <CircleStep key={page} page={page} index={index} currentPage={this.state.page}/>
            </div>
          </>
        ))}
      </div>
    )
  }
}

class CircleStep extends Component {
  render(){
    let future = this.props.index > this.props.currentPage
    return (
      <div className='progress-bar-circle-step-container'>
        <div className={classnames('progress-bar-circle', {'progress-bar-circle-future':future})}>
          {this.props.index >= this.props.currentPage ? (
            <div>{this.props.index + 1}</div>
          ) : (
            <Checkmark />
          )}
        </div>
        <div 
          className={classnames('progress-bar-circle-step-page', 
            {'progress-bar-circle-step-page-future':future})}
        >
          {this.props.page}
        </div>
      </div>
    )
  }
}
      
export default TutorialContextHOC(ProgressBar) 
