import React, { Component } from 'react';
import withRouterAndRef from "../withRouterAndRef"

export const TutorialContext = React.createContext()

class TutorialProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      PAGES : [
        "FCSC",
        "BLOCKCHAIN",
        "CONTRACTS",
        "NFTs",
        "TOKEN SWAP",
        "GAMIFICATION",
        "COMPLETE"
      ],
      next:(page)=>this.nextPage(page),
    }
  }

  nextPage(currentPage){
    currentPage = currentPage.toUpperCase()
    let index = this.state.PAGES.indexOf(currentPage)
    let next = this.state.PAGES[index + 1].toLowerCase()
    this.props.history.push({pathname:'/'+next})
  }

 
  // if the Tutorial is not logged in, render the <Login />
  render() {
    return  (
      <TutorialContext.Provider value={this.state}>
        {this.props.children}
      </TutorialContext.Provider>
    )
  }
}

export default withRouterAndRef(TutorialProvider);
