import React, { Component } from "react"
import "./Footer.css"

class Footer extends Component {

  render(){
    return (
        <div className='footer'>
          <img src='https://cdn.hngr.co/fcsc/captain-with-text.webp' alt='Captain'/>
          <div>
            © 2022 Friends of Captain Social Club
          </div>
        </div> 
    )
  }
}
      
export default Footer 



